import { ConfirmModal } from 'aa_common/front-end/components'
import { RefactorWarningMessage } from 'common/components/refactor-warning-message'
import { CONFIRM_TYPES } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation, ImportExportButton as Button } from 'components/molecules'
import { RoleList } from 'components/organisms'
import { ListPageTemplate } from 'components/templates'
import React from 'react'

import { useFetchRoleListData } from './hooks/use-fetch-role-list-data'
import { useHandleActions } from './hooks/use-handle-actions'

const t = loadNs(['common', 'pages/settings/roles'])

export const RoleListContainer = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('role')} />
  const { isCreate, roleListSelector } = useFetchRoleListData()

  const {
    handleOnAddNew,
    handleOnDeleteModalCancel,
    isDeleteModalShow,
    handleConfirmDelete,
    handleDeleteItemClick,
    handleEditItemClick,
  } = useHandleActions()

  return (
    <>
      <RefactorWarningMessage />
      <ListPageTemplate maxWidth={900} breadcrumb={breadcrumb}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
          <Button type="import" disabled={!isCreate} onClick={handleOnAddNew}>
            {t('actions.add')}
          </Button>
        </div>
        <RoleList
          loading={roleListSelector.loading}
          data={roleListSelector.data}
          onEditClick={handleEditItemClick}
          onDeleteClick={handleDeleteItemClick}
        />
        <ConfirmModal
          type={CONFIRM_TYPES.DELETE}
          visible={isDeleteModalShow}
          title={t('delete_role_modal_title')}
          message={t('delete_role_modal_message')}
          onCancel={handleOnDeleteModalCancel}
          onOK={handleConfirmDelete}
        />
      </ListPageTemplate>
    </>
  )
}
