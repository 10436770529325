/* eslint-disable no-case-declarations */
import { messageSuccess } from 'aa_common/front-end'
import { hasRetroactiveAssets } from 'common/helpers'
import { formatDate } from 'common/helpers/format-helper'
import { Asset } from 'common/models'
import { FixedAssetEventsService } from 'common/open-api'
import { importBulkChangeAction, importBulkChangeSlice } from 'components/organisms/assets/AssetImportForm/store'
import useRetroactiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import { useLongPolling } from 'hooks/useLongPolling'
import { TFunction } from 'i18next'
import { isEqual } from 'lodash'
import { IMPORT_EXPORT_EVENT_TYPES } from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { CREATE_BULK_ASSET_LIST_REQUEST } from 'store/assetImport/actions'
import { selectAssetImportListData } from 'store/assetImport/selectors'

import { createColumn } from './helper'

export const useImportTables = (type: any, data: any) => {
  const dataTable = useMemo(() => {
    const dataSource = data?.map(function (item: any) {
      return { ...item, asset_branch_code: `${item?.asset_code} - ${item?.branch_code}` }
    })
    const fixedColumn = [
      createColumn('capture_image', { width: 60, fixed: 'left' }),
      createColumn('asset_branch_code', { width: 130, fixed: 'left' }),
      createColumn('asset_name', { width: 170, fixed: 'left' }),
    ]

    switch (type) {
      case IMPORT_EXPORT_EVENT_TYPES.START_USAGE:
        const columnsStartUsage = [
          ...fixedColumn,
          createColumn('start_usage_at', {
            width: 120,
            render: value => {
              return <div>{formatDate(value)}</div>
            },
          }),
          createColumn('cause_code'),
          createColumn('cause_name', { width: 170 }),
          createColumn('reference_link', { width: 170 }),
        ]
        return { dataSource, columns: columnsStartUsage }

      case IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT:
        const columnsImpairment = [
          ...fixedColumn,
          createColumn('impaired_at', {
            width: 120,
            render: value => {
              return <div>{formatDate(value)}</div>
            },
          }),
          createColumn('cause_code'),
          createColumn('cause_name', { width: 170 }),
          createColumn('impair_dem', { width: 170 }),
          createColumn('reference_link', { width: 170 }),
        ]
        return { dataSource, columns: columnsImpairment }
      case IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION:
        const columnsAssetTypeModification = [
          ...fixedColumn,
          createColumn('asset_type_depreciable_property_tax_name', { width: 250 }),
          createColumn('asset_type_corporate_tax_name', { width: 200 }),
          createColumn('asset_type_structure_corporate_tax', { width: 150 }),
          createColumn('asset_type_detail_corporate_tax', { width: 150 }),
        ]
        return { dataSource, columns: columnsAssetTypeModification }

      case IMPORT_EXPORT_EVENT_TYPES.SALE:
        const dataSourceSale = data?.map(function (item: any) {
          return {
            ...item,
            asset_branch_code: `${item?.asset_code} - ${item?.branch_code}`,
            disposal_excise_name: item?.disposal_excise_short_name,
            sold_excise_name: item?.sold_excise_short_name,
            sold_tax_amount: item?.manual_sold_excise_value,
          }
        })
        const columnsSale = [
          ...fixedColumn,
          createColumn('sold_at', {
            width: 130,
            render: value => {
              return <div>{formatDate(value)}</div>
            },
          }),

          createColumn('cause_code', { width: 120 }),
          createColumn('cause_name', { width: 170 }),
          createColumn('sold_amount', { width: 130 }),

          createColumn('sold_excise_master_id', { width: 130 }),
          createColumn('sold_excise_name', { width: 150 }),
          createColumn('sold_tax_amount', { width: 120 }),
          createColumn('sold_destination', { width: 150 }),
          createColumn('disposal_cost', { width: 120 }),
          createColumn('disposal_excise_master_id', { width: 130 }),
          createColumn('disposal_excise_name', { width: 130 }),
          createColumn('disposal_tax_amount', { width: 130 }),
          createColumn('disposal_destination', { width: 150 }),
          createColumn('depreciation_ended_method', { width: 150 }),
          createColumn('reference_link', { width: 150 }),
        ] as any

        return { dataSource: dataSourceSale, columns: columnsSale }

      case IMPORT_EXPORT_EVENT_TYPES.RETIREMENT:
        const dataSourceRetire = data?.map(function (item: any) {
          return {
            ...item,
            asset_branch_code: `${item?.asset_code} - ${item?.branch_code}`,
            disposal_excise_name: item?.disposal_excise_short_name,
          }
        })
        const columnsRetire = [
          ...fixedColumn,
          createColumn('retired_at', {
            width: 150,
            render: value => {
              return <div>{formatDate(value)}</div>
            },
          }),
          createColumn('cause_code', { width: 150 }),
          createColumn('cause_name', { width: 150 }),
          createColumn('disposal_cost', { width: 150 }),
          createColumn('disposal_excise_master_id', { width: 150 }),
          createColumn('disposal_excise_name', { width: 150 }),
          createColumn('disposal_tax_amount', { width: 150 }),
          createColumn('disposal_destination', { width: 160 }),
          createColumn('depreciation_ended_method', { width: 160 }),
          createColumn('reference_link', { width: 170 }),
        ]
        return { dataSource: dataSourceRetire, columns: columnsRetire }
      case IMPORT_EXPORT_EVENT_TYPES.MOVEMENT:
        const dataSourceMovement = data?.map(function (item: any) {
          return {
            ...item,
            asset_branch_code: `${item?.asset_code} - ${item?.branch_code}`,
            department_code_after_change: item?.department_code,
            area_code_after_change: item?.area_code,
          }
        })
        const columnsMovement = [
          ...fixedColumn,
          createColumn('moved_at', {
            width: 250,
            render: value => {
              return <div>{formatDate(value)}</div>
            },
          }),
          createColumn('cause_code', { width: 150 }),
          createColumn('cause_name', { width: 150 }),
          createColumn('department_code_after_change', { width: 250 }),
          createColumn('area_code_after_change', { width: 250 }),
          createColumn('reference_link', { width: 170 }),
        ]
        return { dataSource: dataSourceMovement, columns: columnsMovement }

      default:
        return { dataSource: [], columns: [] }
    }
  }, [type])

  return {
    dataTable,
  }
}

export const useSubmitImport = (t: TFunction) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const { importBulkChangeType, data } = importBulkChangeSlice.useSlice()
  const importList = useSelector<any, Asset[]>(selectAssetImportListData, isEqual)
  const confirmModal = useRetroactiveAssetModal()

  const onError = (errorBiids: string[]) => {
    importBulkChangeAction.setErrorBiids(errorBiids)
  }
  const onSuccess = () => {
    push('/assets')
    messageSuccess(t('messages.imported_bulk_change_success'))
  }
  const importCsvBulkchange = useLongPolling('importCsvBulkchange', { onError, onSuccess })

  const bulkChangeEventPolling = async () => {
    try {
      const res: any = await FixedAssetEventsService.eventsCreate({
        file_url: data?.upload_url,
        event_type_code: importBulkChangeType,
      })
      importCsvBulkchange.setJobId(res?.data?.id)
    } catch (e) {
      /* empty */
    }
  }

  const createBulk = () => {
    importBulkChangeType ? bulkChangeEventPolling() : dispatch({ type: CREATE_BULK_ASSET_LIST_REQUEST })
  }
  const handleSubmit = () => {
    importBulkChangeAction.clearErrors()

    if (!importBulkChangeType && hasRetroactiveAssets(importList, currentMonthlyClosing)) {
      // Open the retroactive asset warning modal
      confirmModal.open({
        onOKClick: () => createBulk(),
      })
    } else {
      // No retroactive asset in the list
      createBulk()
    }
  }

  return {
    handleSubmit,
    importCsvBulkchange,
  }
}
