import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { RoleListContainer } from 'containers/settings/role-list-container/role-list-container'
import React from 'react'

import { RoleListPageOld } from './role-list-page'

const RoleListPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <RoleListContainer />, <RoleListPageOld />)}</>
}

export default RoleListPage
