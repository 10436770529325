import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end/antd'
import { BreadcrumbNavigation } from 'components/molecules'
import TabPageTemplate from 'components/templates/TabPageTemplate'
import { TabPageTemplateSettings } from 'components/templates/TabPageTemplate/model'
import { getAccountItems, resetAccountItems } from 'containers/master/account-items/store'
import i18n from 'i18n'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { cleanupJournalSettings } from 'store/accounting/journal/actions'
import { cleanupMonthlyClosingNextSeting, fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { fetchFullTerms } from 'store/settings/accountingPeriod/actions'

import AutoJournalSettingTab from './tabs/AutoJournalSettingTab'
import GeneralJournalSettingTab from './tabs/GeneralJournalSettingTab'

const TAB_KEY = {
  GENERAL: 'general-journal-settings-tab',
  ASSET_CATEGORY: 'asset-category-journal-setting-tab',
}

const AutoJournalSettingPage = () => {
  const [activeTab, setActiveTab] = useState<string>(TAB_KEY.GENERAL)
  const dispatch = useDispatch()
  const journalItemWrapperRef = useRef<any>(null)

  const router = useLocation()

  useEffect(() => {
    if (router && router.hash !== '') {
      // set focus tab
      const focusTab = router.hash.slice(1)
      setActiveTab(focusTab)
    } else {
      // set default
      const defaultTab = TAB_KEY.GENERAL
      setActiveTab(defaultTab)
      global && global.history.replaceState('', '', `/setting/auto-journal-settings#${defaultTab}`)
    }
  }, [router])

  const handleOnTabChange = (activeKey: string) => {
    setActiveTab(previousTabKey => {
      if (previousTabKey !== activeKey) {
        if (previousTabKey === TAB_KEY.ASSET_CATEGORY) {
          journalItemWrapperRef.current.closeModal()
        }
        global && global.history.replaceState('', '', `/setting/auto-journal-settings#${activeKey}`)
        return activeKey
      }
      return previousTabKey
    })
  }

  useEffect(() => {
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchFullTerms())
    getAccountItems()

    return () => {
      dispatch(cleanupMonthlyClosingNextSeting())
      dispatch(cleanupJournalSettings())
      resetAccountItems()
    }
  }, []) // eslint-disable-line

  const renderTitle = () => (
    <div style={{ display: 'flex' }}>
      {i18n.t('pages.accounting.AutoJournalSettings.title')}
      <Tooltip content={i18n.t('pages.accounting.AutoJournalSettings.tooltip.title')} tooltipStyles={{ marginLeft: 5 }}>
        <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
      </Tooltip>
    </div>
  )
  const breadcrumb = <BreadcrumbNavigation sectionName={renderTitle()} />

  const tabSettings: TabPageTemplateSettings = {
    activeKey: activeTab,
    onTabChange: handleOnTabChange,
    tabs: [
      {
        children: <GeneralJournalSettingTab />,
        key: TAB_KEY.GENERAL,
        label: i18n.t('pages.accounting.AutoJournalSettings.basic_settings'),
      },
      {
        children: <AutoJournalSettingTab ref={journalItemWrapperRef} />,
        key: TAB_KEY.ASSET_CATEGORY,
        label: i18n.t('pages.accounting.AutoJournalSettings.auto_journal_asset_category'),
      },
    ],
  }

  return <TabPageTemplate tabsSettings={tabSettings} breadcrumb={breadcrumb} />
}

export default AutoJournalSettingPage
