import styled from '@emotion/styled'
import { Spin } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { MainTemplate } from 'components/templates'
import { find, get, isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { getUserInfoSelector, selectCurrentOffice } from 'store/session/selectors'

import { parseOfficeNumber } from './helpers'

const t = loadNs(['pages/settings/office'])

const { REACT_APP_NAVIS_SERVICE_URL } = process.env

const OFFICE_TENANT_TYPES = [
  {
    value: 'corporate',
    label: t('officeType.corporate'),
  },
  {
    value: 'individual',
    label: t('officeType.individual'),
  },
]

const renderOfficeType = (type: string) => {
  const target = find(OFFICE_TENANT_TYPES, item => item.value === type)
  return target ? target.label : ''
}

export const OfficeShowContainer = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('breadcrumb_title')} />
  const currentOfficeSelector = useSelector(selectCurrentOffice, isEqual)
  const userInfo = useSelector(getUserInfoSelector, isEqual)

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <StyledLink>
        <a
          href={`${REACT_APP_NAVIS_SERVICE_URL}/sso?tenant_uid=${userInfo?.office_id}&redirect_path=${REACT_APP_NAVIS_SERVICE_URL}/app&login_hint=${userInfo?.email}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('go_to_navis')}
        </a>
      </StyledLink>
      <StyleUserInfo>
        <Spin loading={currentOfficeSelector.loading}>
          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_name')}</h4>
            </div>
            <div className="content">{get(currentOfficeSelector.data, 'name', '')}</div>
          </div>

          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_number')}</h4>
            </div>
            <div className="content">
              {parseOfficeNumber(get(currentOfficeSelector.data, 'identification_code', ''))}
            </div>
          </div>

          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_type')}</h4>
            </div>
            <div className="content">{renderOfficeType(currentOfficeSelector.data?.tenant_type)}</div>
          </div>
        </Spin>
      </StyleUserInfo>
    </MainTemplate>
  )
}

const StyleUserInfo = styled.div`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  max-width: 900px;

  .field-item {
    color: #333333;
    background: white;
    display: flex;
    border-bottom: 1px solid #d4d8dd;
    padding: 15px 10px;

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 4px 4px;
      border-bottom: none;
    }

    .label {
      width: 280px;
      padding-right: 10px;
      h4 {
        color: #333333;
        margin-bottom: 0;
        font-weight: 500;
      }
    }

    .content {
      padding-left: 10px;
    }
  }
`

const StyledLink = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 900px;
  margin-bottom: 10px;
  a {
    font-size: 13px;
    width: 152px;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 244) 100%);
    border: 1px solid rgb(212, 216, 221);
    padding: 4px 10px;
    border-radius: 4px;
  }
`
