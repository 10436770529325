/* eslint-disable react-hooks/exhaustive-deps */
import { AutoCompleteSelect, Button } from 'aa_common/front-end/antd'
import { LinkLabel } from 'aa_common/front-end/components'
import { Button as AntdButton } from 'antd'
import classNames from 'classnames'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'
import { fetchTermPeriods, fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermData, selectTermsData } from 'store/settings/accountingPeriod/selectors'

import Pagination from './components/Pagination'
import { ACTIVE_PARAMS, getActiveParamLabel, KEY_ACTIVE_PARAMS, MAP_JOURNAL_ID } from './consts'
import { ButtonClass, HorizontalBorder, WrapperButton } from './styles'

const { RedirectLink } = LinkLabel

interface Props {
  valuesForm: any
  journalCount: any
  monthlyClosingCurrent?: any
  onFetchJournals: (values: any) => void
  onFetchCountOfJournalEvents: (term_month_id: number) => void
}

const t = loadNs(['common', 'pages/accounting/journal-list'])
const Form = ({
  valuesForm,
  journalCount,
  onFetchJournals,
  onFetchCountOfJournalEvents,
  monthlyClosingCurrent,
}: Props) => {
  const dispatch = useDispatch()
  const [activeParams, setActiveParams] = useState({
    ...ACTIVE_PARAMS,
    [KEY_ACTIVE_PARAMS.ACQUISITION]: true,
  })
  const [termValue, setTermValue] = useState<number | null>(null)
  const [index, setIndex] = useState(0)
  const [monthValue, setMonthValue] = useState<number | null>(null)
  const [values, setValues] = useState(valuesForm)

  const terms = useSelector(selectTermsData, isEqual)
  const { permissions: autoJournalPermissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)
  const { permissions: monthlyClosingPermission } = useUserPermission(FEATURES_ID.MONTHLY_CLOSING)

  const { data: termPeriods } = useSelector(selectTermData)

  const termMonths = termPeriods?.term_months || []

  useEffect(() => {
    !terms && dispatch(fetchTerms())
  }, [terms])

  useEffect(() => {
    if (monthlyClosingCurrent) {
      setTermValue(monthlyClosingCurrent?.from_term_month?.term_id)
      setMonthValue(monthlyClosingCurrent?.from_term_month?.month)
    }
  }, [monthlyClosingCurrent])

  useEffect(() => {
    termValue && dispatch(fetchTermPeriods(termValue))
  }, [termValue])

  useEffect(() => {
    if (termMonths?.length) {
      let termMonthId = null
      let index = 0
      if (monthValue) {
        termMonths.forEach((monthItem: any, indexMonth: number) => {
          if (monthItem.month === monthValue) {
            termMonthId = monthItem.id
            index = indexMonth
          }
        })
      } else {
        termMonthId = termMonths[0]?.id
      }
      setIndex(index)
      setValues({
        ...values,
        term_month_id: termMonthId,
      })
      onFetchCountOfJournalEvents(termMonthId)
    }
  }, [termMonths])

  useEffect(() => {
    values.term_month_id && onFetchJournals(values)
  }, [values])

  const location = useLocation()
  const history = useHistory()
  const setActiveKey = (keyActive: string) => {
    history.replace({
      hash: keyActive,
    })
    if (activeParams[keyActive]) return
    const activeParamsTemp = {
      ...ACTIVE_PARAMS,
      [keyActive]: true,
    }
    setActiveParams(activeParamsTemp)
    const valuesTemp = {
      ...values,
      journal_event_type: parseInt(
        Object.keys(activeParamsTemp)
          .filter(item => activeParamsTemp[item])
          .map(item => MAP_JOURNAL_ID[item])
          .join(','),
        10
      ),
    }
    setValues(valuesTemp)
  }

  useEffect(() => {
    const { hash } = location
    if (hash) {
      setActiveKey(hash.replace('#', ''))
    }
  }, [])

  const onClickActive = (keyActive: string) => {
    history.replace({
      hash: keyActive,
    })
    setActiveKey(keyActive)
  }

  const parseTerms = () => {
    return (
      !!terms &&
      terms.map(term => ({
        value: term.id,
        text: `${t('format.year_term', { year: term.year, term: term.period })}`,
      }))
    )
  }

  const handleOnChange = (value: any, key: string) => {
    setMonthValue(null)
    setValues({
      ...values,
      [key]: value,
    })
    onFetchCountOfJournalEvents(value)
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {terms && (
          <div className="test" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <AutoCompleteSelect
                value={termValue}
                name="term_id"
                items={parseTerms()}
                textField="text"
                valueField="value"
                style={{ width: 150 }}
                allowClear={false}
                onChange={value => setTermValue(value)}
              />
              <Pagination
                termPeriods={termMonths}
                keyValue="id"
                keyName="month"
                onChange={value => handleOnChange(value, 'term_month_id')}
                index={index}
                setIndex={setIndex}
              />
            </div>
            <div>
              <span>
                {t('export_guide_text_first')}
                <RedirectLink
                  showTooltipMessage={!monthlyClosingPermission.isRead ? t('actions.no_permission') : undefined}
                  disabled={!monthlyClosingPermission.isRead}
                  style={{ fontSize: 14 }}
                  redirectTo="/accounting/monthly-closing"
                >
                  {t('export_guide_link')}
                </RedirectLink>
                {t('export_guide_text_last')}
              </span>
              <Button
                style={{ marginLeft: '20px' }}
                key="auto-journal-setting"
                color="grey"
                showTooltipMessage={!autoJournalPermissions.isRead ? t('actions.no_permission') : undefined}
                disabled={!autoJournalPermissions.isRead}
                onClick={() => dispatch(changeLocation('/setting/auto-journal-settings'))}
              >
                {t('auto_journal_setting')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <HorizontalBorder />
      <WrapperButton>
        {Object.keys(activeParams).map(key => (
          <AntdButton
            key={key}
            className={classNames(ButtonClass, { isNotActive: !activeParams[key] })}
            onClick={() => onClickActive(key)}
            type={activeParams[key] ? 'primary' : 'ghost'}
            shape="round"
          >
            {getActiveParamLabel()[key](journalCount[key])}
          </AntdButton>
        ))}
      </WrapperButton>
    </>
  )
}

export default Form
