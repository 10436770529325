import { PresenterOneAccountItemResponse } from 'common/open-api'
import { find, omit, pick } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { initFormKeys, initialFormValues } from './const'

export const handleDisableAccoutItem = (item: any) => {
  const accountItemFields = [
    'acquisition_debit_asset_category_',
    'acquisition_credit_payment_category_',
    'depreciation_debit_',
    'depreciation_credit_accumulated_',
    'depreciation_credit_asset_category_',
    'impairment_debit_loss_',
    'impairment_credit_accumulated_impairment_',
    'impairment_credit_asset_category_',
    'retirement_debit_accumulated_depreciation_',
    'retirement_credit_asset_category_',
    'retirement_debit_accumulated_impairment_',
    'retirement_debit_loss_',
    'retirement_credit_payment_category_',
    'sale_debit_accumulated_depreciation_',
    'sale_credit_asset_category_',
    'sale_debit_accumulated_impairment_',
    'sale_debit_revenue_',
    'sale_credit_payment_category_',
    'sale_debit_loss_',
    'sale_credit_gain_',
  ]
  const accountItemNameFields = new Map([
    ['accountItem', 'account_item'],
    ['accountItemId', 'account_item_id'],
    ['accountSubItem', 'account_sub_item'],
    ['accountSubItemId', 'account_sub_item_id'],
  ])

  accountItemFields.forEach(accountItemField => {
    if (!item[`${accountItemField}${accountItemNameFields.get('accountItem')}`]?.is_active) {
      // Set blank for account item
      accountItemNameFields.forEach(accountItemNameField => {
        item[`${accountItemField}${accountItemNameField}`] = null
      })
    } else if (!item[`${accountItemField}${accountItemNameFields.get('accountSubItem')}`]?.is_active) {
      // Set blank for sub account item
      item[`${accountItemField}${accountItemNameFields.get('accountSubItem')}`] = null
      item[`${accountItemField}${accountItemNameFields.get('accountSubItemId')}`] = null
    }
  })

  return item
}

export const convertFormPayload = (item: any) => {
  if (!item) return initialFormValues

  const itemSetting = handleDisableAccoutItem(item)

  return {
    ...pick(itemSetting, initFormKeys),
    term_year_period: item.from_term_month.term_id,
    month_id: item.from_term_month.id,
  }
}

export const convertSubmitPayload = (item: ReturnType<typeof convertFormPayload>, selectedAssetCategory: any) => {
  const { month_id: from_term_month_id, ...rest } = omit({ ...item }, 'term_year_period')

  return { ...rest, from_term_month_id, asset_category_id: selectedAssetCategory.id }
}

export const useSubAccOptions = (accountItemField: string, accountItems: PresenterOneAccountItemResponse[]) => {
  const accountItemValue = useWatch({
    name: accountItemField,
  })

  return useMemo(() => {
    const subAccountItem = find(accountItems, item => item.id === accountItemValue)?.account_sub_items
    return subAccountItem?.filter(subAccountItem => subAccountItem?.is_active) || []
  }, [accountItemValue, accountItems])
}

export const useFieldBinding = (targetAccount: string, watchAccount: string) => {
  const { setValue } = useFormContext()

  const accountItemField = `${targetAccount}_item_id`
  const accountSubItemField = `${targetAccount}_sub_item_id`

  const watchAccountItemValue = useWatch({ name: `${watchAccount}_item_id` })
  const watchAccountSubItemValue = useWatch({ name: `${watchAccount}_sub_item_id` })

  useEffect(() => {
    setValue(accountItemField, watchAccountItemValue)
    setValue(accountSubItemField, watchAccountSubItemValue)
  }, [accountItemField, accountSubItemField, watchAccountItemValue, watchAccountSubItemValue]) // eslint-disable-line
}
