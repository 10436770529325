import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { PresenterOneAccountItemResponse } from 'common/open-api'
import { RenderFormProps } from 'components/molecules/Form'
import { JournalItemSettingTable } from 'components/templates'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useFieldBinding, useSubAccOptions } from '../helper'

interface ImpairmentSettingTableProps {
  accountItems: PresenterOneAccountItemResponse[]
}

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const ImpairmentSettingTable = ({ accountItems }: ImpairmentSettingTableProps) => {
  const { loading, control, setValue } = useFormContext() as RenderFormProps
  const {
    permissions: { isCRU },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  useFieldBinding('impairment_credit_asset_category_account', 'acquisition_debit_asset_category_account')

  const debitLossAccountSubItems = useSubAccOptions('impairment_debit_loss_account_item_id', accountItems)
  const accumulatedImpairmentAccountSubItems = useSubAccOptions(
    'impairment_credit_accumulated_impairment_account_item_id',
    accountItems
  )
  const creditAssetCategoryAccountSubItems = useSubAccOptions(
    'impairment_credit_asset_category_account_item_id',
    accountItems
  )

  const onAccountItemChanged = (accountSubItemField: string) => setValue(accountSubItemField, null)

  return (
    <JournalItemSettingTable title={t('impairment_settings_table')}>
      <tbody>
        <tr>
          <th className="section-name">{t('impairment_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              disabled={loading || !isCRU}
              name="impairment_debit_loss_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('impairment_debit_loss_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              disabled={loading || !isCRU}
              name="impairment_debit_loss_account_sub_item_id"
              items={debitLossAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name">{t('accumulated_impairment_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              disabled={loading || !isCRU}
              name="impairment_credit_accumulated_impairment_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('impairment_credit_accumulated_impairment_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              disabled={loading || !isCRU}
              name="impairment_credit_accumulated_impairment_account_sub_item_id"
              items={accumulatedImpairmentAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
        </tr>
        <tr>
          <th className="section-name" />
          <td colSpan={2} />

          <th className="section-name">{t('asset_category')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              disabled
              name="impairment_credit_asset_category_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('impairment_credit_asset_category_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              disabled
              name="impairment_credit_asset_category_account_sub_item_id"
              items={creditAssetCategoryAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
        </tr>
      </tbody>
    </JournalItemSettingTable>
  )
}

export default ImpairmentSettingTable
