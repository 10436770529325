import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { PresenterDepreciationOfOneYearResponse } from 'common/open-api'
import { MoneyInput } from 'components/molecules'

import { useCalculateAcceleratedDepreciation } from '../hooks/use-calculate-accelerated-depreciation'
import { StyledAntTable } from '../styles'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])
export const EditableTable = ({ selectedDate, depreciationResults }: Props) => {
  useNsReady()
  const { dataSource, handleInputChange } = useCalculateAcceleratedDepreciation(depreciationResults, selectedDate)

  const renderYenValue = (value: number) => yen(value)
  const columns = [
    {
      title: t('accelerated_depreciation_table_fields.period'),
      dataIndex: 'period',
      width: 125,
      render: (period: number, record: any) => (
        <>
          <If condition={record.isParent}>
            <span>{t('format.year_term', { year: record.fiscal_year, term: period })}</span>
          </If>
          <If condition={!record.isParent}>
            <span>{t('format.term_year_month', { month: record.month, year: record.year })}</span>
          </If>
        </>
      ),
    },
    {
      title: t('accelerated_depreciation_table_fields.depreciation_amount_before_accelerated_depreciation'),
      dataIndex: 'depreciation_amount',
      width: 160,
      render: renderYenValue,
    },
    {
      title: t('accelerated_depreciation_table_fields.depreciation_amount'),
      dataIndex: 'adjust_depreciation_amount',
      width: 160,
      render: (value: number, record: any, index: number) => {
        return (
          <>
            <If condition={record.isEditable}>
              <MoneyInput
                name={`record-${record.id}`}
                defaultValue={value}
                onChange={(value?: number) => handleInputChange(index, value)}
              />
            </If>

            <If condition={!record.isEditable}>
              <span>{yen(value)}</span>
            </If>
          </>
        )
      },
    },
    {
      title: t('accelerated_depreciation_table_fields.book_value_after_accelerated_depreciation'),
      dataIndex: 'end_of_term_book_value',
      width: 160,
      render: renderYenValue,
    },
  ] as any

  return (
    <StyledAntTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record: any) => ifElse(!record.isParent, 'child', '')}
    />
  )
}

type Props = {
  selectedDate: string | undefined
  depreciationResults: any
}

export type DataSource = PresenterDepreciationOfOneYearResponse & {
  adjust_depreciation_amount: number
  isEditable: boolean
  parent_term_id: number
  term_id?: number
  isProcessingMonth?: boolean
}
