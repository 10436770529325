import { Role } from 'aa_common/front-end/models'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { deleteRoleCall } from 'store/settings/role/actions'

export const useHandleActions = () => {
  const dispatch = useDispatch()

  const [deletedItem, setDeletedItem] = useState<Role | null>(null)

  const handleOnAddNew = () => dispatch(changeLocation('/setting/roles/new'))

  const handleEditItemClick = useCallback(
    (item: Role) => {
      dispatch(changeLocation(`/setting/roles/${item.biid}/edit`))
    },
    [dispatch]
  )

  const handleDeleteItemClick = useCallback((item: Role) => setDeletedItem(item), [])

  const handleOnDeleteModalCancel = () => setDeletedItem(null)

  const handleConfirmDelete = () => {
    if (deletedItem?.biid) {
      dispatch(deleteRoleCall(deletedItem.biid))
      setDeletedItem(null)
    }
  }

  const isDeleteModalShow = !!deletedItem

  return {
    handleOnAddNew,
    handleEditItemClick,
    handleDeleteItemClick,
    handleOnDeleteModalCancel,
    handleConfirmDelete,
    isDeleteModalShow,
  }
}
