import { RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import moment from 'moment'

import { DataSource } from '../components/editable-table'

export const calculateAcceleratedDepreciation = (dataSource: DataSource[], rowIndex: number, value?: number) => {
  const newDataSource = [...dataSource]
  for (let i = 0; i < newDataSource.length; i++) {
    if (newDataSource[i].isEditable && value) {
      if (i === rowIndex) {
        newDataSource[i].adjust_depreciation_amount = value
        newDataSource[i].end_of_term_book_value = (newDataSource[i - 1]?.end_of_term_book_value as number) - value
      }

      if (i > rowIndex) {
        newDataSource[i].beginning_book_value = newDataSource[i - 1]?.end_of_term_book_value
        newDataSource[i].end_of_term_book_value =
          (newDataSource[i - 1]?.end_of_term_book_value as number) - newDataSource[i].adjust_depreciation_amount
      }
    }
  }

  return newDataSource
}

export const filteredDataSourceByLedgerSetting = (
  dataSource: DataSource[],
  selectedDate: string,
  retirementDepreciationEndedMethod: number
) => {
  return dataSource.filter(({ end_date, term_start_date }: any) => {
    const isValidTerm = moment(term_start_date).year() <= moment(selectedDate).year()
    if (retirementDepreciationEndedMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_MONTH) {
      return !term_start_date ? moment(end_date).isBefore(selectedDate, 'month') : isValidTerm
    }

    if (retirementDepreciationEndedMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR) {
      return !term_start_date
        ? moment(end_date).isBefore(selectedDate, 'year')
        : moment(term_start_date).year() < moment(selectedDate).year()
    }

    if (
      [
        RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH,
        RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT,
      ].includes(retirementDepreciationEndedMethod)
    ) {
      return !term_start_date ? moment(end_date).isSameOrBefore(selectedDate, 'month') : isValidTerm
    }

    return true
  })
}

export const setInitialDepreciationAmount = (dataSource: DataSource[]) => {
  let firstEditableItem: any = {}
  const numberOfEditableItem = dataSource.filter((record: any) => record.isEditable).length
  for (let i = 0; i < dataSource.length; i++) {
    if (dataSource[i]?.isProcessingMonth) {
      firstEditableItem = dataSource[i]
    }

    const beginningBookValueOfFirstEditableItem = firstEditableItem.beginning_book_value
    const depreciationAmountChanged = Math.floor(beginningBookValueOfFirstEditableItem / numberOfEditableItem)
    if (dataSource[i]?.isEditable) {
      dataSource[i].adjust_depreciation_amount = Math.floor(
        beginningBookValueOfFirstEditableItem / numberOfEditableItem
      )

      dataSource[i].end_of_term_book_value =
        (dataSource[i - 1]?.end_of_term_book_value as number) - dataSource[i].adjust_depreciation_amount
    }

    if (i === dataSource.length - 1) {
      dataSource[i].adjust_depreciation_amount =
        beginningBookValueOfFirstEditableItem - depreciationAmountChanged * (numberOfEditableItem - 1)
    }
  }

  return dataSource
}
