import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { PresenterOneAccountItemResponse } from 'common/open-api'
import { RenderFormProps } from 'components/molecules/Form'
import { JournalItemSettingTable } from 'components/templates'
import useUserPermission from 'lib/hooks/useUserPermission'
import { useFormContext } from 'react-hook-form'

import { useFieldBinding, useSubAccOptions } from '../helper'

interface RetirementSettingTableProps {
  accountItems: PresenterOneAccountItemResponse[]
}

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const RetirementSettingTable = ({ accountItems }: RetirementSettingTableProps) => {
  const { loading, control, setValue } = useFormContext() as RenderFormProps
  const {
    permissions: { isCRU },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  useFieldBinding('retirement_credit_asset_category_account', 'acquisition_debit_asset_category_account')

  useFieldBinding('retirement_debit_accumulated_depreciation_account', 'depreciation_credit_accumulated_account')

  useFieldBinding('retirement_debit_accumulated_impairment_account', 'impairment_credit_accumulated_impairment_account')

  const retirementDebitAccumulatedDepreciationAccountSubItems = useSubAccOptions(
    'depreciation_credit_accumulated_account_item_id',
    accountItems
  )
  const retirementCreditACAccountSubItems = useSubAccOptions(
    'acquisition_debit_asset_category_account_item_id',
    accountItems
  )
  const retirementDebitLossAccountSubItems = useSubAccOptions('retirement_debit_loss_account_item_id', accountItems)
  const retirementCreditPaymentCategoryAccountSubItems = useSubAccOptions(
    'retirement_credit_payment_category_account_item_id',
    accountItems
  )
  const retirementDebitAccumulatedImpairmentAccountSubItems = useSubAccOptions(
    'impairment_credit_accumulated_impairment_account_item_id',
    accountItems
  )

  const onAccountItemChanged = (accountSubItemField: string) => setValue(accountSubItemField, null)

  return (
    <JournalItemSettingTable title={t('retirement_settings_table')}>
      <tbody>
        <tr>
          <th className="section-name">{t('accumulated')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_accumulated_depreciation_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_accumulated_depreciation_account_sub_item_id"
              items={retirementDebitAccumulatedDepreciationAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>

          <th className="section-name">{t('asset_category')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_credit_asset_category_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_credit_asset_category_account_sub_item_id"
              items={retirementCreditACAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
        </tr>
        <tr>
          <th className="section-name">{t('accumulated_impairment_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_accumulated_impairment_account_item_id"
              items={accountItems}
              disabled
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_accumulated_impairment_account_sub_item_id"
              items={retirementDebitAccumulatedImpairmentAccountSubItems}
              disabled
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name" />
          <td colSpan={2} />
        </tr>
        <tr>
          <th className="section-name">{t('retirement_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_loss_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('retirement_debit_loss_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_debit_loss_account_sub_item_id"
              items={retirementDebitLossAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name">
            {t('payment_category')}
            <br />({t('disposal_cost')})
          </th>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_credit_payment_category_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('retirement_credit_payment_category_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="retirement_credit_payment_category_account_sub_item_id"
              items={retirementCreditPaymentCategoryAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
        </tr>
      </tbody>
    </JournalItemSettingTable>
  )
}

export default RetirementSettingTable
