import omit from 'lodash/omit'
import moment from 'moment'

import { DataSource } from '../components/editable-table'

export const getCalculatedDataSource = (data: DataSource[]) => {
  const totals = data.reduce((acc: { [key: number]: number }, item: any) => {
    acc[item.parent_term_id] = (acc[item.parent_term_id] || 0) + item.adjust_depreciation_amount
    return acc
  }, {})

  return data.map(item => {
    if (item.term_id && totals[item.term_id]) {
      return { ...item, adjust_depreciation_amount: totals[item.term_id] }
    }
    return item
  })
}

export const convertToDataSource = (
  depreciationData: any,
  currentMonthlyClosing: { startDate: string; endDate: string }
) => {
  const { startDate, endDate } = currentMonthlyClosing
  const result: any = []

  function flatten(item: any, options = {}) {
    const newItem = {
      ...omit({ ...item, adjust_depreciation_amount: item.depreciation_amount }, 'months'),
      ...options,
    }
    result.push(newItem)

    item?.months?.forEach((child: any) => {
      const isEditable = moment(child.start_date).isSameOrAfter(startDate, 'month')

      return flatten(child, {
        id: child.term_month_id,
        parent_term_id: item.term_id,
        adjust_depreciation_amount: child.depreciation_amount,
        isProcessingMonth:
          moment(child.start_date).isSame(moment(startDate)) && moment(child.end_date).isSame(moment(endDate)),
        isEditable,
      })
    })
  }

  depreciationData?.forEach((item: any) => flatten(item, { isParent: true }))
  return result
}
