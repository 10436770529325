import { AutoCompleteSelect, Button, Checkbox, Spin } from 'aa_common/front-end/antd'
import { Layout } from 'antd'
import { CONFIRM_TYPES, FEATURES_ID } from 'common/constants'
import {
  convertTermListData,
  formatMonth,
  formatYear,
  generateAccountPeriods,
  generateTermMonths,
  getAddNewNextSettings,
  getCountItems,
  getSettingByValidAt,
  getTermMonthById,
} from 'common/helpers'
import { FORM_MODES, GeneralJournalSettingItem, GeneralJournalSettingItemSubmitPayload, TermMonth } from 'common/models'
import GeneralJournalSettingForm from 'components/organisms/accounting/GeneralJournalSettingForm'
import GeneralJournalSettingList from 'components/organisms/accounting/GeneralJournalSettingList'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteGeneralJournalSettingsTerm,
  fetchJournalGeneralSettingsTerms,
  saveGeneralJournalSettingsItem,
  updateGeneralJournalSettingsItem,
} from 'store/accounting/journal/actions'
import { selectorGeneralJournalSettingTerms } from 'store/accounting/journal/selectors'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectFullTermsList } from 'store/settings/accountingPeriod/selectors'

import { LayoutClass } from './styles'

const { Content } = Layout

const GeneralJournalSettingTab = () => {
  const [detailMode, setDetailMode] = useState(false)
  const [formMode, setFormMode] = useState<FORM_MODES>(FORM_MODES.VIEW)
  const [validAtValue, setValidAtValue] = useState<any>()
  const [nextSettings, setNextSettings] = useState<TermMonth | null>(null)
  const [termYearPeriodValue, setTermYearPeriod] = useState<number>()
  const [termMonthValue, setTermMonth] = useState<number>()
  const [editItem, setEditItem] = useState<GeneralJournalSettingItem | null>(null)
  const [copyItem, setCopyItem] = useState<GeneralJournalSettingItem | null>(null)
  const { permissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)
  const modal = useModal()

  const dispatch = useDispatch()

  const generalSettingsSelector = useSelector(selectorGeneralJournalSettingTerms, isEqual)

  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const accountPeriods = useSelector(selectFullTermsList, isEqual)

  useEffect(() => {
    async function loadByCurrentMonthlyClosing() {
      if (currentMonthlyClosing) {
        const period = currentMonthlyClosing.monthly_closing_setting.term.id
        const monthId = currentMonthlyClosing.from_term_month.id
        setTermYearPeriod(period)
        setTermMonth(monthId)
      }
    }

    loadByCurrentMonthlyClosing()
  }, [currentMonthlyClosing])

  useEffect(() => {
    if (termMonthValue) {
      const targetMonth = getTermMonthById(termMonthValue, accountPeriods.data)
      targetMonth && setValidAtValue(targetMonth.start_date)
    }
  }, [termMonthValue, accountPeriods.data])

  const handleTermYearPeriodChanged = (termYearPeriod: any) => {
    setTermYearPeriod(termYearPeriod)
    const currentMonthlyClosingInThisYear = currentMonthlyClosing.monthly_closing_setting.term.period === termYearPeriod

    // Set default value when change term year
    // If current monthly closing in selected year, choose the current monthly closing month value
    // Otherwise choose the first month of selected term year period
    const termYear = find(accountPeriods.data, item => item.id === termYearPeriod)
    const firstMonthOfTermYear = first(termYear?.term_months)
    setTermMonth(currentMonthlyClosingInThisYear ? currentMonthlyClosing.from_term_month.id : firstMonthOfTermYear?.id)
  }

  const handleTermMonthChanged = (value: any) => setTermMonth(value)

  useEffect(() => {
    dispatch(fetchJournalGeneralSettingsTerms())
  }, []) // eslint-disable-line

  const settingTermList = useMemo(() => {
    const convertedData = convertTermListData(generalSettingsSelector.data)
    const settingByValidAt = getSettingByValidAt(convertedData, validAtValue)

    return !detailMode ? settingByValidAt : convertedData
  }, [generalSettingsSelector.data, validAtValue, detailMode])

  useEffect(() => {
    // Get next month settings for add new journal settings
    async function executeGetNextSettings() {
      const latestSettings = first(generalSettingsSelector.data)

      if (latestSettings && accountPeriods.data?.length > 0) {
        const result = getAddNewNextSettings(latestSettings, accountPeriods.data)
        setNextSettings(result)
      }
    }

    executeGetNextSettings()
  }, [generalSettingsSelector.data, accountPeriods.data])

  const isLatestRecord = useMemo(() => {
    if (!detailMode) {
      const currentSettings = first(settingTermList)
      return currentSettings?.from_term_month && !currentSettings.to_term_month
    }

    return false
  }, [detailMode, settingTermList])

  const latestRecord = useMemo(() => {
    const currentSettings = first(generalSettingsSelector.data)
    return currentSettings
  }, [generalSettingsSelector.data])

  const memoizedAccountPeriods = useMemo(() => generateAccountPeriods(accountPeriods.data), [accountPeriods.data])

  const memoizedTermMonths = useMemo(() => {
    const termYear = find(accountPeriods.data, item => item.id === termYearPeriodValue)
    return generateTermMonths(termYear?.term_months || [])
  }, [termYearPeriodValue, accountPeriods.data])

  const handleOnItemEditClick = useCallback((item: GeneralJournalSettingItem) => {
    setEditItem(item)
    setFormMode(FORM_MODES.EDIT)
  }, [])

  const handleOnItemDeleteClick = useCallback((deletedItem: GeneralJournalSettingItem) => {
    modal
      .confirm({
        title: i18n.t('components.GeneralAutoJournalSettings.delete_journal_setting_item_title'),
        message: i18n.t('components.GeneralAutoJournalSettings.delete_journal_setting_item_message', {
          month: formatMonth(deletedItem?.from_term_month?.month),
          year: formatYear(deletedItem?.from_term_month?.term?.year),
        }),
        type: CONFIRM_TYPES.DELETE,
      })
      .then((isOKClick: boolean) => {
        if (isOKClick) {
          dispatch(deleteGeneralJournalSettingsTerm(deletedItem.id as number))
        }
      })
  }, []) // eslint-disable-line

  const handleOnItemAddClick = useCallback((item: GeneralJournalSettingItem) => {
    // store for copy function
    setCopyItem(item)
    setFormMode(FORM_MODES.NEW)
  }, [])

  const handleOnEditModalCancel = useCallback(() => {
    setEditItem(null)
    setCopyItem(null)
    setFormMode(FORM_MODES.VIEW)
  }, [])

  const handleButtonEditClick = () => {
    const convertedData = convertTermListData(generalSettingsSelector.data)
    const settingByValidAt = getSettingByValidAt(convertedData, validAtValue)

    if (settingByValidAt.length > 0) {
      setFormMode(FORM_MODES.EDIT)
      setEditItem(first(settingByValidAt))
    }
  }

  const handleFormSubmit = useCallback(
    async (payload: GeneralJournalSettingItemSubmitPayload | null, id?: number | null) => {
      const isModify = formMode === FORM_MODES.EDIT && detailMode
      const isCreate = formMode === FORM_MODES.NEW && detailMode
      const isEdit = formMode === FORM_MODES.EDIT && !detailMode
      const isDifferentFromTermMonth = payload?.from_term_month_id !== get(latestRecord, 'from_term_month.id')

      try {
        if (payload) {
          const isUpdate = isModify || (isEdit && !isDifferentFromTermMonth)
          if (id && isUpdate) {
            dispatch(updateGeneralJournalSettingsItem(id, payload))
          } else if (isCreate || (isEdit && isDifferentFromTermMonth)) {
            dispatch(saveGeneralJournalSettingsItem(payload))
          }
          handleOnEditModalCancel()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [dispatch, formMode, detailMode, latestRecord, handleOnEditModalCancel]
  )

  const countItems = useMemo(
    () => getCountItems(generalSettingsSelector.data, currentMonthlyClosing),
    [generalSettingsSelector.data, currentMonthlyClosing]
  )

  return (
    <Layout className={LayoutClass}>
      <Content id="general-settings-form-wrapper">
        <div style={{ padding: 10, paddingBottom: 100, maxWidth: 900 }}>
          <Spin loading={generalSettingsSelector.loading}>
            <div className="top-header-template">
              <div className="left">
                <AutoCompleteSelect
                  name="term_year_period"
                  items={memoizedAccountPeriods}
                  textField="label"
                  valueField="value"
                  disabled={detailMode}
                  value={termYearPeriodValue}
                  onChange={handleTermYearPeriodChanged}
                  allowClear={false}
                  style={{ marginRight: 5, width: 120 }}
                />
                <AutoCompleteSelect
                  name="term_month"
                  items={memoizedTermMonths}
                  disabled={detailMode}
                  textField="label"
                  valueField="value"
                  value={termMonthValue}
                  onChange={handleTermMonthChanged}
                  allowClear={false}
                  style={{ width: 120 }}
                />
                <span className="display-status-text">{i18n.t('common.list_display_status')}</span>
              </div>
              <div className="middle">
                <Checkbox name="display-all" onChange={event => setDetailMode(event.checked)}>
                  <span>
                    {i18n.t('components.GeneralAutoJournalSettings.display_all_periods')}
                    <span style={{ color: detailMode ? '#D4D8DD' : '#333333' }}>
                      {i18n.t('components.GeneralAutoJournalSettings.future_past_count', {
                        past_count: countItems.pastItems,
                        future_count: countItems.futureItems,
                      })}
                    </span>
                  </span>
                </Checkbox>
              </div>
              <div className="right">
                <Button
                  showTooltipMessage={!permissions.isCRU ? i18n.t('common.actions.no_permission') : undefined}
                  disabled={!isLatestRecord || detailMode || !permissions.isCRU}
                  color="grey"
                  onClick={handleButtonEditClick}
                >
                  {i18n.t('common.actions.edit')}
                </Button>
              </div>
            </div>
            <GeneralJournalSettingList
              isDetailMode={detailMode}
              currentMonthlyClosing={currentMonthlyClosing}
              data={settingTermList}
              onAddNewClick={handleOnItemAddClick}
              onEditClick={handleOnItemEditClick}
              onDeleteClick={handleOnItemDeleteClick}
            />
          </Spin>
        </div>
      </Content>
      <GeneralJournalSettingForm
        isDetailMode={detailMode}
        loading={generalSettingsSelector.loading}
        formMode={formMode}
        nextSettings={nextSettings}
        editItem={editItem}
        copyItem={copyItem}
        onFormSubmit={handleFormSubmit}
        onCancelClick={handleOnEditModalCancel}
      />
    </Layout>
  )
}

export default GeneralJournalSettingTab
