import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetImportResultsContainer } from 'containers/assets/asset-import-result'
import React from 'react'

import { AssetImportResultsPageOld } from './index_old'

const AssetImportResultsPage = () => {
  const { isEnable } = useCheckFlag(FeatureFlagNames.RefactorPagePhase3)
  return isEnable ? <AssetImportResultsContainer /> : <AssetImportResultsPageOld />
}

export default AssetImportResultsPage
