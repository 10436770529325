import { Button, clearNullDataDeep, defaultTo, If, ifElse, Spin } from 'aa_common/front-end'
import { BottomAction, ErrorsList } from 'aa_common/front-end/components'
import { Table } from 'antd'
import { DEFAULT_PAGE_SIZE } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { Asset } from 'common/models'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { Pagination } from 'components/molecules'
import { importBulkChangeAction, importBulkChangeSlice } from 'components/organisms/assets/AssetImportForm/store'
import { isEmpty, isEqual } from 'lodash'
import { IMPORT_EXPORT_EVENT_TYPES } from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { importExportEventTypeSelectorSlice } from 'pages/assets/AssetListPage/store'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CLEANUP_ASSET_IMPORT, setEditAssetItem } from 'store/assetImport/actions'
import { selectAssetImportListData, selectAssetImportListErrors } from 'store/assetImport/selectors'

import { getColumns, getDetailError, parseDataForTable } from './helper'
import { useImportTables, useSubmitImport } from './hooks'
import { NoteContainer, StyledPaginationWrapper, Wrapper } from './styles'

const { t, useNsReady } = getNs(['common', 'components/organisms/settings/setting-import-result-list'])

export const AssetImportResultList: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const importList = useSelector<any, Asset[]>(selectAssetImportListData, isEqual)
  const errorList = useSelector(selectAssetImportListErrors, isEqual)
  const store = importBulkChangeSlice.useSlice()
  const { importEventType } = importExportEventTypeSelectorSlice.useSlice()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  const { importBulkChangeType, data, errorBiids, errors } = store

  const bulkChangeData = useImportTables(importBulkChangeType, data?.events)
  const {
    location: { pathname, key: locationKey },
    push,
    goBack,
  } = useHistory()

  const handleRowClick = (record: any, index: number) => {
    dispatch(setEditAssetItem(clearNullDataDeep(importList[index])))
  }

  const { handleSubmit, importCsvBulkchange } = useSubmitImport(t)

  const isEmptyImportList = isEmpty(importList)

  useEffect(() => {
    if (isEmptyImportList && !importBulkChangeType) {
      const previousUrl = pathname.substring(0, pathname.lastIndexOf('/'))
      // if can't go back we will using the previousUrl, eg: /assets/import/results -> /assets/import
      locationKey ? goBack() : push(previousUrl)
    }
    return function cleanup() {
      dispatch({ type: CLEANUP_ASSET_IMPORT })
      importBulkChangeAction.reset()
    }
  }, []) // eslint-disable-line

  const bulkChangeImportDataSource = useMemo(() => {
    return bulkChangeData?.dataTable?.dataSource?.map((item: any) => ({
      ...item,
      isError: errorBiids.includes(item.biid),
    }))
  }, [errorBiids])

  const { errorDetails, errorLines } = getDetailError(errors)

  const isImportForBulkChange = useMemo(() => {
    return importEventType !== null && importEventType !== IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER
  }, [importEventType])

  const paginationConfig = useMemo(() => {
    const total = ifElse(
      isImportForBulkChange,
      bulkChangeImportDataSource?.length,
      parseDataForTable(importList)?.length
    )

    const totalPages = Math.ceil(total / DEFAULT_PAGE_SIZE)

    return { total, totalPages }
  }, [isImportForBulkChange, bulkChangeImportDataSource, importList])

  const handlePaginationChange = (page: number) => {
    setLoading(true)

    setTimeout(() => {
      setCurrentPage(page)
      setLoading(false)
    }, 200)
  }

  const hasError: boolean = !!(errorList || !!errorDetails.length)
  const { isEnable: isEnableReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const { isEnable: isEnableImprovePerformance } = useCheckFlag(FeatureFlagNames.ImprovePerformanceFAImportPreviewPage)
  const isStickColumn = IMPORT_EXPORT_EVENT_TYPES.SALE || IMPORT_EXPORT_EVENT_TYPES.RETIREMENT

  const tableDataSource = useMemo(() => {
    const dataSource = ifElse(isImportForBulkChange, bulkChangeImportDataSource, parseDataForTable(importList))

    return isEnableImprovePerformance
      ? dataSource?.slice((currentPage - 1) * DEFAULT_PAGE_SIZE, currentPage * DEFAULT_PAGE_SIZE)
      : dataSource
  }, [currentPage, isImportForBulkChange, bulkChangeImportDataSource, importList, isEnableImprovePerformance])

  return (
    <Spin loading={importCsvBulkchange.loading}>
      <Wrapper>
        <NoteContainer>{t(isImportForBulkChange ? 'bulk_change_import_notice_message' : 'notice_msg')}</NoteContainer>
        <If condition={hasError}>
          <ErrorsList
            title={t('error_header')}
            errors={errorList || errorDetails}
            style={{ marginTop: 10, maxHeight: 150, overflowY: 'auto' }}
          />
        </If>
        <If condition={isEnableImprovePerformance}>
          <StyledPaginationWrapper>
            <Pagination
              name="fixed-asset-import-preview"
              totalPages={paginationConfig.totalPages}
              totalRecords={paginationConfig.total}
              value={currentPage}
              onPageChanged={handlePaginationChange}
            />
          </StyledPaginationWrapper>
        </If>
        <div>
          <If condition={isImportForBulkChange}>
            <Table
              loading={loading}
              style={ifElse(isStickColumn, { marginTop: 10, width: 'calc(100vw - 180px)' }, { marginTop: 10 })}
              columns={defaultTo(bulkChangeData?.dataTable?.columns, [])}
              dataSource={tableDataSource}
              pagination={false}
              scroll={ifElse(isStickColumn, { x: true }, {})}
              onRow={(record: any, rowIndex) => ({
                // rowIndex + 1 because the error line is 1-based
                className: record.isError || errorLines.includes((rowIndex as number) + 1) ? 'error' : '',
              })}
            />
          </If>
          <If condition={!isImportForBulkChange}>
            <Table
              loading={loading}
              style={{ marginTop: 10 }}
              columns={getColumns(isEnableReduction)}
              dataSource={tableDataSource}
              pagination={false}
              onRow={(record: any, rowIndex: any) => {
                return {
                  className: ifElse(record.is_error, 'error', ''),
                  onClick: () => {
                    handleRowClick(record, rowIndex)
                  }, // click row
                }
              }}
            />
          </If>
        </div>
        <BottomAction id="submit-bottom" style={{ justifyContent: 'flex-end', background: 'rgba(255, 255, 255, 0.8)' }}>
          <Button disabled={hasError} data-testid="submit-import-btn" style={{ height: 42 }} onClick={handleSubmit}>
            {t('actions.register')}
          </Button>
        </BottomAction>
      </Wrapper>
    </Spin>
  )
}
