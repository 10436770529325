import { messageSuccess } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { FixedAssetsService } from 'common/open-api'
import { handleErrorResponse } from 'common/utils/response-helper'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { DataSource } from '../components/editable-table'

const { t } = getNs(['common'])
export const useSubmitAcceleratedDepreciation = (
  dataSource: DataSource[],
  onModalClose: () => void,
  selectedDate?: string
) => {
  const { biid } = useParams<{ biid: string }>()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      const depreciationMonths = dataSource
        .filter((item: any) => !item.isParent && item.isEditable)
        .map(({ term_month_id, adjust_depreciation_amount, end_of_term_book_value }: any) => ({
          term_month_id,
          adjust_depreciation_amount,
          ending_book_value: end_of_term_book_value,
        }))

      const payload = {
        selected_date: selectedDate,
        depr_months: depreciationMonths,
      }

      const response = await FixedAssetsService.depreciationResultsAcceleratedUpdate(biid, payload)
      if (response.data) {
        messageSuccess(t('messages.updated_success'))
        onModalClose()
      }
    } catch (error) {
      handleErrorResponse(error)
    } finally {
      setSubmitting(false)
    }
  }

  return { handleSubmit, isSubmitting }
}
