import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { fetchDataWithService } from 'common/helpers'
import { PresenterOneTagResponse, TagsService } from 'common/open-api'

const initFilter = {}
const initState: { tags: PresenterOneTagResponse[] } = {
  tags: [],
}

const fetchData: FilterFunction<typeof initState, typeof initFilter> = async () => {
  const { data: tags } = await fetchDataWithService(TagsService.tagsList)
  return { data: { tags } }
}

export const tagListSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'master/tags',
  initState,
  initFilter,
  fetchData
)
