import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import CauseForm from 'components/organisms/settings/CauseForm'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCauseItem } from 'store/settings/cause/selectors'

import { useConstructContainer, useHandleSubmit } from './hooks'

const { t, useNsReady } = getNs(['pages/settings/causes'])

export const CauseNewContainer = () => {
  useNsReady()
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={`${t('add')}`} />
  const causeItemSelector = useSelector(selectCauseItem, isEqual)

  useConstructContainer()

  const handleSubmit = useHandleSubmit()

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <CauseForm errors={causeItemSelector.errors} onSubmit={handleSubmit} loading={causeItemSelector.loading} />
    </MainTemplate>
  )
}
