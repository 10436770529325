import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentForm from 'components/organisms/settings/DepartmentForm'
import { MainTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import React from 'react'
import { useDispatch } from 'react-redux'
import { cleanupDepartment } from 'store/settings/department/actions'

import { useDepartmentCopy } from './hooks/use-department-copy'

const t = loadNs(['pages/settings/departments'])

const DepartmentCopy = withRouterTrap(() => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('copy')} />
  const { department, errors, loading, masterData, handleSubmit } = useDepartmentCopy()
  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <DepartmentForm
        values={department}
        errors={errors}
        onSubmit={handleSubmit}
        prepressCategories={masterData.prepress_category}
        type={FORM_MODES.COPY}
        loading={loading}
      />
    </MainTemplate>
  )
})

export const DepartmentCopyContainer = () => {
  const dispatch = useDispatch()
  return <DepartmentCopy path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}
