import { defaultTo } from 'aa_common/front-end/helpers'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD } from 'common/constants'
import { formatDate, yen, yenCheckNull } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { Asset } from 'common/models'
import get from 'lodash/get'

const { t } = getNs([
  'components/organisms/settings/setting-import-result-list',
  'components/organisms/assets/asset-import-result-list',
])

export const MoneyTemplate = (value: string) => {
  return <div style={{ textAlign: 'right' }}>{value}</div>
}

type ColumnOptions = {
  width?: number
  render?: (value: string) => JSX.Element
  fixed?: boolean | 'left' | 'right'
}

export const createColumn = (titleKey: string, options: ColumnOptions = {}) => {
  const { width = 120, render, fixed } = options

  const propsOnPerCell = {
    style: {
      whiteSpace: 'nowrap',
    },
  }

  return {
    title: t(titleKey as unknown as Parameters<typeof t>),
    dataIndex: titleKey,
    key: titleKey,
    width,
    onCell: () => propsOnPerCell,
    onHeaderCell: () => propsOnPerCell,
    ...(fixed && { fixed }),
    ...(render && { render }),
  }
}

const generateColumnByLedger = () => {
  const columnsOfLedger = []
  const options: ColumnOptions = { width: 120, render: MoneyTemplate }
  for (let i = 1; i <= 3; i++) {
    columnsOfLedger.push(
      createColumn(`depreciation_method_${i}_code`),
      createColumn(`depreciation_method_${i}_name`),
      createColumn(`service_life_${i}`),
      createColumn(`service_life_after_change_${i}`),
      createColumn(`memorandum_value_${i}`),
      createColumn(`revised_acquisition_amount_${i}`, options),
      createColumn(`book_value_at_change_${i}`, options),
      createColumn(`accounting_beginning_book_value_${i}`, options),
      createColumn(`transition_book_value_${i}`, options),
      createColumn(`accumulated_depreciation_amount_${i}`, options),
      createColumn(`accumulated_impairment_amount_${i}`, options),
      createColumn(`accumulated_over_depreciation_amt_${i}`, options),
      createColumn(`depreciated_months_${i}`),
      createColumn(`usage_period_months_${i}`),
      createColumn(`residual_amount_${i}`)
    )
  }

  return columnsOfLedger
}

const generateFirstColumnSet = (isEnableReduction?: boolean) => {
  const reductionAmountColumn = [] as any[]
  if (isEnableReduction) {
    reductionAmountColumn.push(createColumn('reduction_amount'))
    reductionAmountColumn.push(createColumn('net_acquisition_cost'))
  }
  return [
    createColumn('code'),
    createColumn('branch_code'),
    createColumn('name', { width: 130 }),
    createColumn('name_kana', { width: 130 }),
    createColumn('acquired_date'),
    createColumn('usage_started_at'),
    createColumn('transition_at'),
    createColumn('acquisition_cost', { width: 120, render: MoneyTemplate }),
    createColumn('excise_master_id'),
    createColumn('excise_name'),
    createColumn('excise_value', { width: 120, render: MoneyTemplate }),
    ...reductionAmountColumn,
    createColumn('acquire_category'),
    createColumn('asset_category_code'),
    createColumn('asset_category_name'),
    createColumn('department_code'),
    createColumn('department_name'),
    createColumn('area_code'),
    createColumn('area_name'),
    createColumn('quantity'),
    createColumn('unit_name'),
    createColumn('acquire_destination_name'),
    createColumn('link'),
  ]
}

const secondColumnSet = [
  createColumn('asset_type_for_depreciable_property_tax_code'),
  createColumn('asset_type_for_depreciable_property_tax'),
  createColumn('has_exemption'),
  createColumn('exemption_numerator'),
  createColumn('exemption_denominator'),
  createColumn('summary_for_asset_depreciation_tax'),
  createColumn('asset_type_for_corporate_tax_code'),
  createColumn('asset_type_for_corporate_tax_name'),
  createColumn('asset_structure_for_corporate_tax'),
  createColumn('asset_detail_for_corporate_tax_name'),
  createColumn('tags'),
  createColumn('memo'),
  createColumn('comment'),
  createColumn('submit_destination'),
  createColumn('pre_submit_number'),
]
export const columns = [...generateFirstColumnSet(), ...generateColumnByLedger(), ...secondColumnSet] as any

const { AUTO_NUMBER, NO_AUTO_NUMBER } = ASSET_CODE_AUTO_GENERATE_SETTING_METHOD
export const formatAssetCode = (asset: Asset) => {
  const { generating_method, code, branch_code } = asset
  return {
    code: generating_method === AUTO_NUMBER ? t('code_auto_gen') : code,
    branch_code: generating_method === NO_AUTO_NUMBER ? branch_code : t('code_auto_gen'),
  }
}

export const parseDataForTable = (settings: Asset[]) => {
  return settings?.map((setting, index) => {
    const { is_excluded } = setting

    return {
      key: index,
      ...formatAssetCode(setting),
      name: setting.name,
      name_kana: setting.name_kana,
      acquired_date: formatDate(setting.acquired_at),
      usage_started_at: formatDate(setting.usage_started_at),
      transition_at: formatDate(setting.transition_at),
      acquisition_cost: yen(is_excluded ? setting.acquisition_cost_excluded_excise : setting.acquisition_cost),
      excise_id: defaultTo(setting.excise?.id as any, ''),
      excise_master_id: defaultTo(setting.excise?.excise_master_id as any, ''),
      excise_name: defaultTo(setting.excise?.short_name, ''),
      excise_value: yen(setting.manual_excise_value || setting.calculated_excise_value || 0),
      reduction_amount: yen(get(setting, 'reduction_amount', 0)),
      net_acquisition_cost: yen(get(setting, 'net_acquisition_cost', 0)),
      acquire_category: get(setting.acquire_category, 'name_jp', ''),
      asset_category_code: get(setting.asset_category, 'code', ''),
      asset_category_name: get(setting.asset_category, 'name', ''),
      department_code: get(setting.department, 'code', ''),
      department_name: get(setting.department, 'name', ''),
      area_code: get(setting.area, 'code', ''),
      area_name: get(setting.area, 'name', ''),
      quantity: setting.quantity,
      unit_name: get(setting.unit, 'name'),
      acquire_destination_name: defaultTo(setting.acquire_destination_name, ''),
      link: defaultTo(setting.urls as any, ''),
      depreciation_method_1_code: get(setting, 'fixed_asset_ledgers[0].depreciation_method_code'),
      depreciation_method_1_name: get(setting, 'fixed_asset_ledgers[0].depreciation_method.name_jp'),
      service_life_1: get(setting, 'fixed_asset_ledgers[0].service_life'),
      service_life_after_change_1: get(setting, 'fixed_asset_ledgers[0].service_life_for_depreciation_calc'),
      memorandum_value_1: get(setting, 'fixed_asset_ledgers[0].memorandum_value'),
      revised_acquisition_amount_1: yenCheckNull(get(setting, 'fixed_asset_ledgers[0].revised_acquisition_amount')),
      book_value_at_change_1: yenCheckNull(get(setting, 'fixed_asset_ledgers[0].acquisition_cost_for_straight_line')),
      accounting_beginning_book_value_1:
        yenCheckNull(get(setting, 'fixed_asset_ledgers[0].transition_beginning_book_value')) ||
        yenCheckNull(get(setting, 'fixed_asset_ledgers[0].accounting_beginning_book_initial_value')),
      transition_beginning_book_value_1: setting.transition_at
        ? yenCheckNull(get(setting, 'fixed_asset_ledgers[0].transition_beginning_book_value'))
        : '',
      transition_book_value_1: yenCheckNull(get(setting, 'fixed_asset_ledgers[0].transition_book_value')),
      accumulated_depreciation_amount_1: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[0].accumulated_depreciation_amount')
      ),
      accumulated_impairment_amount_1: yenCheckNull(get(setting, 'fixed_asset_ledgers[0].accumulated_impaired_amount')),
      accumulated_over_depreciation_amt_1: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[0].accumulated_over_depreciation_amt')
      ),
      depreciated_months_1: get(setting, 'fixed_asset_ledgers[0].depreciated_months'),
      usage_period_months_1: get(setting, 'fixed_asset_ledgers[0].usage_period_months'),
      residual_amount_1: get(setting, 'fixed_asset_ledgers[0].residual_amount'),
      depreciation_method_2_code: get(setting, 'fixed_asset_ledgers[1].depreciation_method.code'),
      depreciation_method_2_name: get(setting, 'fixed_asset_ledgers[1].depreciation_method.name_jp'),
      service_life_2: get(setting, 'fixed_asset_ledgers[1].service_life'),
      service_life_after_change_2: get(setting, 'fixed_asset_ledgers[1].service_life_for_depreciation_calc'),

      memorandum_value_2: get(setting, 'fixed_asset_ledgers[1].memorandum_value'),
      revised_acquisition_amount_2: yenCheckNull(get(setting, 'fixed_asset_ledgers[1].revised_acquisition_amount')),
      book_value_at_change_2: yenCheckNull(get(setting, 'fixed_asset_ledgers[1].acquisition_cost_for_straight_line')),

      accounting_beginning_book_value_2:
        yenCheckNull(get(setting, 'fixed_asset_ledgers[1].transition_beginning_book_value')) ||
        yenCheckNull(get(setting, 'fixed_asset_ledgers[1].accounting_beginning_book_initial_value')),
      transition_beginning_book_value_2: setting.transition_at
        ? yenCheckNull(get(setting, 'fixed_asset_ledgers[1].transition_beginning_book_value'))
        : '',
      transition_book_value_2: yenCheckNull(get(setting, 'fixed_asset_ledgers[1].transition_book_value')),
      accumulated_depreciation_amount_2: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[1].accumulated_depreciation_amount')
      ),
      accumulated_impairment_amount_2: yenCheckNull(get(setting, 'fixed_asset_ledgers[1].accumulated_impaired_amount')),
      accumulated_over_depreciation_amt_2: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[1].accumulated_over_depreciation_amt')
      ),
      depreciated_months_2: get(setting, 'fixed_asset_ledgers[1].depreciated_months'),
      usage_period_months_2: get(setting, 'fixed_asset_ledgers[1].usage_period_months'),
      residual_amount_2: get(setting, 'fixed_asset_ledgers[1].residual_amount'),
      depreciation_method_3_code: get(setting, 'fixed_asset_ledgers[2].depreciation_method.code'),
      depreciation_method_3_name: get(setting, 'fixed_asset_ledgers[2].depreciation_method.name_jp'),
      service_life_3: get(setting, 'fixed_asset_ledgers[2].service_life'),
      service_life_after_change_3: get(setting, 'fixed_asset_ledgers[2].service_life_for_depreciation_calc'),

      memorandum_value_3: get(setting, 'fixed_asset_ledgers[2].memorandum_value'),
      revised_acquisition_amount_3: yenCheckNull(get(setting, 'fixed_asset_ledgers[2].revised_acquisition_amount')),
      book_value_at_change_3: yenCheckNull(get(setting, 'fixed_asset_ledgers[2].acquisition_cost_for_straight_line')),
      accounting_beginning_book_value_3:
        yenCheckNull(get(setting, 'fixed_asset_ledgers[2].transition_beginning_book_value')) ||
        yenCheckNull(get(setting, 'fixed_asset_ledgers[2].accounting_beginning_book_initial_value')),
      transition_beginning_book_value_3: setting.transition_at
        ? yenCheckNull(get(setting, 'fixed_asset_ledgers[2].transition_beginning_book_value'))
        : '',
      transition_book_value_3: yenCheckNull(get(setting, 'fixed_asset_ledgers[2].transition_book_value')),
      accumulated_depreciation_amount_3: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[2].accumulated_depreciation_amount')
      ),
      accumulated_impairment_amount_3: yenCheckNull(get(setting, 'fixed_asset_ledgers[2].accumulated_impaired_amount')),
      accumulated_over_depreciation_amt_3: yenCheckNull(
        get(setting, 'fixed_asset_ledgers[2].accumulated_over_depreciation_amt')
      ),
      depreciated_months_3: get(setting, 'fixed_asset_ledgers[2].depreciated_months'),
      usage_period_months_3: get(setting, 'fixed_asset_ledgers[2].usage_period_months'),
      residual_amount_3: get(setting, 'fixed_asset_ledgers[2].residual_amount'),
      asset_type_for_depreciable_property_tax_code: defaultTo(
        setting.asset_type_for_depreciable_property_tax_code as any,
        ''
      ),
      asset_type_for_depreciable_property_tax_name: defaultTo(setting.asset_type_for_depreciable_property_tax, ''),
      has_exemption: defaultTo(setting.has_exemption as any, ''),
      exemption_numerator: setting.exemption_numerator || '',
      exemption_denominator: setting.exemption_denominator || '',
      summary_for_asset_depreciation_tax: setting.summary_for_asset_depreciation_tax,
      asset_type_for_corporate_tax_code: defaultTo(setting.asset_type_for_corporate_tax_code as any, ''),
      asset_type_for_corporate_tax_name: defaultTo(setting.asset_type_for_corporate_tax, ''),
      asset_structure_for_corporate_tax: setting.asset_structure_for_corporate_tax,
      asset_detail_for_corporate_tax: setting.asset_detail_for_corporate_tax,
      tags: setting.tag_names && setting.tag_names.join('|'),
      memo: setting.memo,
      comment: setting.comment,
      submit_destination: get(setting.area, ['submit_destination', 'name']),
      pre_submit_number: setting.pre_submit_number,
      is_error: get(setting, 'is_error'),
    }
  })
}

export const getDetailError = (errors: any) => {
  const errorDetails: string[] = []
  const errorLines: number[] = []

  errors?.forEach((error: any) => {
    errorDetails.push(error.detail)
    // error.line - 1 because the CSV file header occupies the first line
    errorLines.push(error.line - 1)
  })

  return { errorDetails, errorLines }
}

export const getColumns = (isEnableReduction?: boolean): any => {
  return [...generateFirstColumnSet(isEnableReduction), ...generateColumnByLedger(), ...secondColumnSet]
}
