import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { fetchDataWithService } from 'common/helpers'
import { PresenterOneUnitResponse, UnitsService } from 'common/open-api'

const initFilter = {}
const initState: { units: PresenterOneUnitResponse[] } = {
  units: [],
}

const fetchData: FilterFunction<typeof initState, typeof initFilter> = async () => {
  const { data: units } = await fetchDataWithService(UnitsService.unitsList)
  return { data: { units } }
}

export const unitsSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'master/units',
  initState,
  initFilter,
  fetchData
)
