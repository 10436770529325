/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterJob } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Export<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Export Area in CSV
   *
   * @tags export
   * @name AreasList
   * @summary Export Area
   * @request GET:/export/areas
   */
  areasList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
      'filter[biid]'?: string[]
      'filter[code]'?: string
      'filter[is_show_future]'?: boolean
      'filter[is_show_past]'?: boolean
      'filter[name]'?: string
      'filter[search_key]'?: string
      'filter[submit_destination_id]'?: number | string
      'filter[valid_at]'?: string
      'page[number]'?: number | string
      'page[size]'?: number | string
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/areas`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export asset category from csv file
   *
   * @tags export
   * @name AssetCategoriesList
   * @summary Export the asset category
   * @request GET:/export/asset_categories
   */
  assetCategoriesList = (
    query?: {
      'filter[asset_detail_for_corporate_tax]'?: string
      'filter[asset_structure_for_corporate_tax]'?: string
      'filter[asset_type_for_corporate_tax]'?: (
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 20
        | 30
        | 40
        | 50
        | 60
        | 99
        | 999
      )[]
      'filter[asset_type_for_depreciable_property_tax]'?: (1 | 2 | 3 | 4 | 5 | 6 | 99)[]
      'filter[code]'?: string
      'filter[created_at]'?: string[]
      'filter[depreciation_method]'?: (10 | 11 | 20 | 21 | 22 | 30 | 40 | 50 | 60 | 80 | 90 | 99)[]
      'filter[excise_id]'?: number | string
      'filter[name]'?: string
      'filter[search_key]'?: string
      sort?: string[]
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/asset_categories`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export causes by csv file
   *
   * @tags export
   * @name CausesList
   * @summary Export the causes
   * @request GET:/export/causes
   */
  causesList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/causes`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export journals
   *
   * @tags export
   * @name CurrentMonthlyClosingJournalsList
   * @summary Export journals
   * @request GET:/export/current_monthly_closing/journals
   */
  currentMonthlyClosingJournalsList = (
    query?: {
      /** csv format, plus/smb/standard */
      format?: string
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/current_monthly_closing/journals`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export Department in CSV
   *
   * @tags export
   * @name DepartmentsList
   * @summary Export Department
   * @request GET:/export/departments
   */
  departmentsList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
      'filter[biid]'?: string[]
      'filter[code]'?: string
      'filter[is_show_future]'?: boolean
      'filter[is_show_past]'?: boolean
      'filter[name]'?: string
      'filter[prepress_category_code]'?: number | string
      'filter[search_key]'?: string
      'filter[valid_at]'?: string
      'page[number]'?: number | string
      'page[size]'?: number | string
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/departments`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export depreciation result
   *
   * @tags export
   * @name DepreciationResultsList
   * @summary Export the depreciation result
   * @request GET:/export/depreciation_results
   */
  depreciationResultsList = (
    query: {
      currency_unit?: 1 | 1000
      ledger_setting_id: number | string
      mode?: 0 | 1
      output_unit: 1 | 2
      term_id: number | string
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/depreciation_results`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Export FixedAsset
   *
   * @tags export
   * @name FixedAssetsList
   * @summary Export FixedAsset
   * @request GET:/export/fixed_assets
   */
  fixedAssetsList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
      'filter[acquire_category]'?: (number | string)[]
      'filter[acquire_destination_name]'?: string
      'filter[acquired_at]'?: string[]
      'filter[acquisition_cost]'?: (number | string)[]
      'filter[area_biid]'?: string[]
      'filter[area_codes]'?: string[]
      'filter[asset_category_id]'?: (number | string)[]
      'filter[asset_detail_for_corporate_tax]'?: string
      'filter[asset_status]'?: (1 | 10 | 20 | 29 | 30 | 40)[]
      'filter[asset_structure_for_corporate_tax]'?: string
      'filter[asset_type_for_corporate_tax]'?: (number | string)[]
      'filter[asset_type_for_depreciable_property_tax]'?: (number | string)[]
      'filter[biid]'?: string[]
      'filter[branch_code]'?: string
      'filter[calculate_cost]'?: boolean
      'filter[category_codes]'?: string[]
      'filter[change_situation]'?: number | string
      'filter[code]'?: string
      'filter[created_at]'?: string[]
      'filter[department_biid]'?: string[]
      'filter[department_codes]'?: string[]
      'filter[depreciation_method_code]'?: (number | string)[]
      'filter[event_type_code]'?: 10 | 20 | 30 | 40 | 50 | 60 | 70
      'filter[exemption_denominator]'?: number | string
      'filter[exemption_numerator]'?: number | string
      'filter[include_history]'?: boolean
      'filter[is_get_detail_one_fixed_asset]'?: boolean
      'filter[is_retroactive]'?: boolean
      'filter[journal_id]'?: number | string
      'filter[keyword]'?: string
      'filter[memo]'?: string
      'filter[name]'?: string
      'filter[name_kana]'?: string
      'filter[pre_submit_number]'?: string
      'filter[quantity]'?: (number | string)[]
      'filter[service_life]'?: (number | string)[]
      'filter[tag]'?: string[]
      'filter[unit_id]'?: (number | string)[]
      'filter[usage_started_at]'?: string[]
      'filter[valid_at]'?: string
      'page[number]'?: number | string
      'page[size]'?: number | string
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/fixed_assets`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export FixedAssetForELTax
   *
   * @tags export
   * @name FixedAssetsEltaxList
   * @summary Export FixedAssetForELTax
   * @request GET:/export/fixed_assets/eltax
   */
  fixedAssetsEltaxList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
      'filter[fiscal_year]'?: 2022 | 2023
      'filter[submit_destination_id]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/fixed_assets/eltax`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export Multiple FixedAssetForELTax
   *
   * @tags export
   * @name FixedAssetsEltaxMultipleList
   * @summary Export Multiple FixedAssetForELTax
   * @request GET:/export/fixed_assets/eltax_multiple
   */
  fixedAssetsEltaxMultipleList = (
    query?: {
      'filter[charset]'?: string
      'filter[fiscal_year]'?: 2022 | 2023
      submit_destination_ids?: (number | string)[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterJob, any>({
      path: `/export/fixed_assets/eltax_multiple`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export FixedAssetByEventType
   *
   * @tags export
   * @name FixedAssetsEventTypeList
   * @summary Export FixedAssetByEventType
   * @request GET:/export/fixed_assets/event_type
   */
  fixedAssetsEventTypeList = (
    query?: {
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
      'filter[acquire_category]'?: (number | string)[]
      'filter[acquire_destination_name]'?: string
      'filter[acquired_at]'?: string[]
      'filter[acquisition_cost]'?: (number | string)[]
      'filter[area_biid]'?: string[]
      'filter[area_codes]'?: string[]
      'filter[asset_category_id]'?: (number | string)[]
      'filter[asset_detail_for_corporate_tax]'?: string
      'filter[asset_status]'?: (1 | 10 | 20 | 29 | 30 | 40)[]
      'filter[asset_structure_for_corporate_tax]'?: string
      'filter[asset_type_for_corporate_tax]'?: (number | string)[]
      'filter[asset_type_for_depreciable_property_tax]'?: (number | string)[]
      'filter[biid]'?: string[]
      'filter[branch_code]'?: string
      'filter[calculate_cost]'?: boolean
      'filter[category_codes]'?: string[]
      'filter[change_situation]'?: number | string
      'filter[code]'?: string
      'filter[created_at]'?: string[]
      'filter[department_biid]'?: string[]
      'filter[department_codes]'?: string[]
      'filter[depreciation_method_code]'?: (number | string)[]
      'filter[event_type_code]'?: 10 | 20 | 30 | 40 | 50 | 60 | 70
      'filter[exemption_denominator]'?: number | string
      'filter[exemption_numerator]'?: number | string
      'filter[include_history]'?: boolean
      'filter[is_get_detail_one_fixed_asset]'?: boolean
      'filter[is_retroactive]'?: boolean
      'filter[journal_id]'?: number | string
      'filter[keyword]'?: string
      'filter[memo]'?: string
      'filter[name]'?: string
      'filter[name_kana]'?: string
      'filter[pre_submit_number]'?: string
      'filter[quantity]'?: (number | string)[]
      'filter[service_life]'?: (number | string)[]
      'filter[tag]'?: string[]
      'filter[unit_id]'?: (number | string)[]
      'filter[usage_started_at]'?: string[]
      'filter[valid_at]'?: string
      'page[number]'?: number | string
      'page[size]'?: number | string
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/fixed_assets/event_type`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export increase/decrease result
   *
   * @tags export
   * @name IncreaseDecreaseCostList
   * @summary Export the increase/decrease result
   * @request GET:/export/increase_decrease_cost
   */
  increaseDecreaseCostList = (
    query: {
      currency_unit?: 1 | 1000
      /** @max 12 */
      from_month_serial_number: number | string
      ledger_setting_id: number | string
      output_unit: 1 | 2
      term_id: number | string
      /** @max 12 */
      to_month_serial_number: number | string
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/increase_decrease_cost`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Export journal linkage history
   *
   * @tags export
   * @name JournalLinkageHistoryList
   * @summary Export journals linked to accounting plus in one submission
   * @request GET:/export/journal_linkage_history
   */
  journalLinkageHistoryList = (
    query: {
      /** ID of a linkage history */
      linkage_history_id: number | string
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/journal_linkage_history`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export submit destinations from csv file
   *
   * @tags export
   * @name SubmitDestinationsList
   * @summary Export the submit destinations
   * @request GET:/export/submit_destinations
   */
  submitDestinationsList = (
    query?: {
      'filter[apply_destination]'?: string
      'filter[asset_location_1]'?: string
      'filter[asset_location_2]'?: string
      'filter[asset_location_3]'?: string
      'filter[capital_amount]'?: number | string
      'filter[code]'?: string
      'filter[commercial_name]'?: string
      'filter[company_established_at_month]'?: number | string
      'filter[company_established_at_year]'?: number | string
      'filter[company_name]'?: string
      'filter[company_name_kana]'?: string
      'filter[details_export_setting]'?: number | string
      'filter[has_additional_depreciation_report]'?: boolean
      'filter[has_approval_of_shortened_service_life]'?: boolean
      'filter[has_blue_return]'?: boolean
      'filter[has_borrow_asset]'?: boolean
      'filter[has_depreciation_method_on_ta_for_dbm]'?: boolean
      'filter[has_depreciation_method_on_ta_for_slm]'?: boolean
      'filter[has_non_taxable_asset]'?: boolean
      'filter[has_special_depreciation_or_compress_entry]'?: boolean
      'filter[has_taxation_standard_special]'?: boolean
      'filter[industry_category]'?: string
      'filter[lender_name]'?: string
      'filter[name]'?: string
      'filter[owner_address]'?: string
      'filter[owner_address_kana]'?: string
      'filter[owner_category_of_building_of_company_rented_house]'?: boolean
      'filter[owner_category_of_building_of_company_self_owned]'?: boolean
      'filter[owner_code]'?: string
      'filter[owner_telephone]'?: string
      'filter[owner_zip_code]'?: string
      'filter[person_in_charge_department]'?: string
      'filter[person_in_charge_name]'?: string
      'filter[person_in_charge_telephone]'?: string
      'filter[remark]'?: string
      'filter[representative_name]'?: string
      'filter[representative_name_kana]'?: string
      'filter[representative_position]'?: string
      'filter[search_key]'?: string
      'filter[tax_accountant_name]'?: string
      'filter[tax_accountant_telephone]'?: string
      sort?: string[]
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/submit_destinations`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Export Under Over Depreciation in CSV
   *
   * @tags export
   * @name UnderOverDepreciationList
   * @summary Export UnderOverDepreciation
   * @request GET:/export/under_over_depreciation
   */
  underOverDepreciationList = (
    query: {
      output_unit: 1 | 2
      term_id: number | string
      /** csv charset, if it's not shift_jis, utf-8 is used (value: shift_jis) */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/export/under_over_depreciation`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    })
}
