import { Button } from 'aa_common/front-end/antd'
import { RightDrawer, UnborderCollapse } from 'aa_common/front-end/components'
import { DatePicker } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_RFC } from 'common/constants'
import { formatDate } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { BasicForm } from 'components/molecules'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'

import { useCalculateAcceleratedDepreciation } from '../hooks/use-calculate-accelerated-depreciation'
import { useSubmitAcceleratedDepreciation } from '../hooks/use-submit-accelerated-depreciation'
import { StyledTopSection } from '../styles'
import { EditableTable } from './editable-table'

const { Item } = UnborderCollapse
const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])
export const AcceleratedDepreciationDrawer = ({ depreciationResults, onModalClose }: Props) => {
  useNsReady()
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined)
  const { dataSource } = useCalculateAcceleratedDepreciation(depreciationResults, selectedDate)
  const { handleSubmit, isSubmitting } = useSubmitAcceleratedDepreciation(dataSource, onModalClose, selectedDate)

  const initialValues = {
    selected_date: undefined,
  }

  return (
    <RightDrawer
      title={t('accelerated_depreciation_registration')}
      visible
      onClose={onModalClose}
      width={693}
      extra={
        <Button disabled={!selectedDate || isSubmitting} onClick={handleSubmit}>
          {t('actions.register')}
        </Button>
      }
    >
      <BasicForm initialValues={initialValues} onSubmit={handleSubmit}>
        <StyledTopSection>
          <Item label={t('selected_date')} contentWidth={175}>
            <DatePicker
              name="selected_date"
              allowClear={false}
              format={DATE_FORMAT}
              disabledDate={date => moment(date).isBefore(currentMonthlyClosing?.from_term_month?.start_date)}
              onChange={date => setSelectedDate(formatDate(date, DATE_FORMAT_RFC) as string)}
            />
          </Item>
        </StyledTopSection>
        <EditableTable selectedDate={selectedDate} depreciationResults={depreciationResults} />
      </BasicForm>
    </RightDrawer>
  )
}

type Props = {
  depreciationResults: any
  onModalClose: () => void
}
