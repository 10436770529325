import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { OfficeShowContainer } from 'containers/settings/office-show-container'
import React from 'react'

import { OfficeShowOldPage } from './office-show-page'

const OfficeShowPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <OfficeShowContainer />, <OfficeShowOldPage />)}</>
}

export default OfficeShowPage
