import { FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'
import { cleanupRoles, fetchUserRoles } from 'store/settings/role/actions'
import { selectUserRoleList } from 'store/settings/role/selectors'

export const useFetchRoleListData = () => {
  const dispatch = useDispatch()

  const roleListSelector = useSelector(selectUserRoleList, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission, isEqual)
  const { isCreate } = handlePermissionByFeatureId(FEATURES_ID.SETTING_ROLES, userPermissions)
  useEffect(() => {
    dispatch(fetchUserRoles())

    return () => {
      dispatch(cleanupRoles())
    }
  }, [])

  return { roleListSelector, isCreate }
}
