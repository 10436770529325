import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { fetchDataWithService } from 'common/helpers'
import { AccountItemsService, PresenterOneAccountItemResponse } from 'common/open-api'

const initFilter = {}
const initState: { accountItems: PresenterOneAccountItemResponse[] } = {
  accountItems: [],
}

// @ts-ignore
const fetchData: FilterFunction<typeof initState, typeof initFilter> = async () => {
  const { data: accountItems } = await fetchDataWithService(AccountItemsService.accountItemsList)

  return {
    data: {
      accountItems,
    },
  }
}

export const accountItemsListSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'master/account-items',
  initState,
  initFilter,
  fetchData
)

export const getAccountItems = () => accountItemsListSlice.setFilters({}, true)
export const resetAccountItems = () => accountItemsListSlice.setData(initState)
