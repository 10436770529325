import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'aa_common/front-end/antd'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { Menu } from 'antd'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'

import { StyledArrowIcon, StyledDropdown, StyledDropdownContent, StyledMenu } from '../styles'

const { t, useNsReady } = getNs('components/organisms/assets/asset-detail')

export const DepreciationAdjustmentDropdown = ({ onOpen }: { onOpen: () => void }) => {
  useNsReady()
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const [isShowDepreciationAdjustment, setShowDepreciationAdjustment] = useState(false)

  const fixedAssetLedgers = defaultTo(get(asset, 'fixed_asset_ledgers'), [])
  const accountantLedger = fixedAssetLedgers.find(
    (assetLedger: AssetLedger) => assetLedger.ledger_setting?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
  )
  const depreciationMethodCode = get(accountantLedger, 'depreciation_method_code') as number

  const { isDisabled, tooltipContent } = useMemo(() => {
    const isMethodExcluded = [
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    ].includes(depreciationMethodCode)

    const isSoldOrRetiredOrImpaired = !!asset?.sold_at || !!asset?.retired_at || !!asset?.impaired_at

    const COUNTINUE_DEPRECIATION_CODE = 1
    const isContinueToDepreciate =
      accountantLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD &&
      accountantLedger?.ledger_setting?.bulk_equal_installment_depreciation_method === COUNTINUE_DEPRECIATION_CODE

    const isDisabled = isMethodExcluded || isSoldOrRetiredOrImpaired || isContinueToDepreciate

    const tooltipContentByDmthod: { [key: number]: string } = {
      [DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD]: t('tooltip_content.cannot_adjust_with_d30'),
      [DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET]: t('tooltip_content.cannot_adjust_with_d80'),
      [DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE]: t('tooltip_content.cannot_adjust_with_d90'),
      [DEPRECIATION_METHOD_CODE.NON_DEPRECIATION]: t('tooltip_content.cannot_adjust_with_d99'),
    }
    const tooltipContent =
      isDisabled && isSoldOrRetiredOrImpaired
        ? t('tooltip_content.cannot_adjust_with_events')
        : tooltipContentByDmthod[depreciationMethodCode]

    return { isDisabled, tooltipContent }
  }, [asset, accountantLedger])

  return (
    <Tooltip content={isDisabled ? tooltipContent : undefined} placement="bottomRight">
      <StyledDropdown
        overlay={<DropdownMenu isRegistered={false} onOpen={onOpen} />}
        onVisibleChange={setShowDepreciationAdjustment}
        trigger={['click']}
        visible={isShowDepreciationAdjustment}
        disabled={isDisabled}
        className={ifElse(isDisabled, 'disabled', '')}
        placement="bottomRight"
      >
        <StyledDropdownContent>
          <span>{t('depreciation_adjustment')}</span>
          <StyledArrowIcon icon={faSortDown} className={ifElse(isDisabled, 'disabled', '')} />
        </StyledDropdownContent>
      </StyledDropdown>
    </Tooltip>
  )
}

const DropdownMenu = ({ isRegistered, onOpen }: { isRegistered: boolean; onOpen: () => void }) => (
  <StyledMenu>
    <Menu.Item key="register" onClick={onOpen}>
      {t('accelerated_depreciation_registration')}
    </Menu.Item>
    <Menu.Item key="cancel" disabled={!isRegistered}>
      {t('cancel_accelerated_depreciation')}
    </Menu.Item>
  </StyledMenu>
)
