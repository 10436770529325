import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'common/constants'
import { isEqual } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export type Filters = Record<string, any>
export type FilterSortPagingEvents = {
  onFiltersChanged?: (filters: Filters) => void
  onSortsChanged: (sorts: string[]) => void
  onPageChanged?: (pageNumber: number) => void
}
export type UseFiltersSortsPagingOption = {
  totalRecords: number
  initFilters?: Filters
  initSort?: string[]
  initPageNumber?: number
  initPageSize?: number
  wait?: number
}

export default function useFiltersSortsPaging(options: UseFiltersSortsPagingOption) {
  const {
    totalRecords,
    initFilters = {},
    initSort = [],
    initPageNumber = DEFAULT_PAGE_NUMBER,
    initPageSize = DEFAULT_PAGE_SIZE,
    wait = 50,
  } = options

  const [sort, setSort] = useState(initSort)
  const [pageNumber, setPageNumber] = useState(initPageNumber)
  const [pageSize] = useState(initPageSize)

  const [filters, setFilters] = useState<Filters>(initFilters)
  const filtersRef = useRef(filters)
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const debouncedSetFilters = useDebouncedCallback((filters: Filters, forceUpdate = false) => {
    if (isEqual(filtersRef.current, filters) && !forceUpdate) return
    filtersRef.current = filters
    setFilters(filters)
    setPageNumber(DEFAULT_PAGE_NUMBER)
  }, wait)

  return useMemo(() => {
    return {
      filters,
      setFilters: debouncedSetFilters,
      sort,
      setSort,
      pageNumber,
      setPageNumber,
      pageSize,
      totalPages: Math.ceil(
        (parseInt(`${totalRecords}`, 10) || 0) / (parseInt(`${pageSize}`, 10) || DEFAULT_PAGE_SIZE)
      ),
    }
  }, [filters, sort, pageNumber, pageSize, totalRecords, debouncedSetFilters])
}
