import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { RoleNewContainer } from 'containers/settings/role-new-container/role-new-container'
import React from 'react'

import { RoleNewPageOld } from './role-new-page'

const RoleNewPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <RoleNewContainer />, <RoleNewPageOld />)}</>
}

export default RoleNewPage
