import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRoleSample } from 'store/settings/role/actions'
import { selectRoleDetail } from 'store/settings/role/selectors'

export const useFetchData = () => {
  const dispatch = useDispatch()

  const { data, loading } = useSelector(selectRoleDetail, isEqual)

  useEffect(() => {
    dispatch(fetchRoleSample())
  }, [])
  return { data, loading }
}
