import * as assetApi from 'api/app/asset'
import { parseValidationError } from 'common/helpers'
import { checkRetroActiveFixedAsset, getAssetPayload, parseDataForForm } from 'common/helpers/asset-helper'
import { Asset, FORM_MODES } from 'common/models'
import { AssetForm } from 'components/organisms'
import useRetroactiveModal from 'components/templates/modal/RetroactiveAssetModal'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { setEditAssetItem, setImportAssetList } from 'store/assetImport/actions'
import { selectAssetImportItemData, selectAssetImportListData } from 'store/assetImport/selectors'

import { StyledDrawer } from './styles'

export const AssetFormDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const confirmModal = useRetroactiveModal()

  const [visible, setVisible] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState()

  const assetItem = useSelector<any, Asset>(selectAssetImportItemData)
  const assetList = useSelector<any, Asset[]>(selectAssetImportListData)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)

  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const isValidateAssetResLoading = validateAssetRes.isLoading

  const handleClose = () => {
    dispatch(setEditAssetItem(null))
    setFormErrors(undefined)
  }

  const handleSubmit = (assetItem: any) => {
    validateAsset({ ...getAssetPayload(assetItem), dry_run: true })
      .then((result: any) => {
        const { error } = result
        if (error) {
          setFormErrors(parseValidationError(error))
          return false
        }

        setFormErrors(undefined)
        const newAssetList = [...assetList]
        newAssetList[assetItem.index as number] = {
          ...parseDataForForm(result?.data),
          index: assetItem.index,
        } as Asset

        dispatch(setImportAssetList(newAssetList))

        return checkRetroActiveFixedAsset({
          res: result,
          assetItem,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then((isOK: any) => {
        if (isOK === true) {
          dispatch(setEditAssetItem(null))
        }
      })
  }

  useLayoutEffect(() => {
    if (visible) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [visible])

  useEffect(() => {
    setVisible(!!assetItem)
  }, [assetItem])

  return (
    <StyledDrawer
      title=""
      placement="right"
      closable
      onClose={handleClose}
      visible={visible}
      width={1060}
      destroyOnClose
    >
      <div id="asset-edit-import-form">
        {assetItem && (
          <AssetForm
            isValidateAssetLoading={isValidateAssetResLoading}
            initialAssetData={assetItem}
            errors={formErrors}
            formMode={FORM_MODES.IMPORT}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </StyledDrawer>
  )
}
