import { parseErrorResponse } from 'common/utils/response-helper'

export const fetchDataWithService = async <T>(serviceFunction: () => Promise<{ data: T }>) => {
  try {
    const data = (await serviceFunction())?.data
    return {
      data,
    }
  } catch (err: any) {
    parseErrorResponse(err)
    return { data: [] }
  }
}
