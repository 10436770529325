import { checkIsAfterDate } from 'aa_common/front-end/helpers'
import { RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import { findAccountingLedger } from 'common/helpers'
import { LedgerSettingsItem } from 'common/models'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetItemData } from 'store/asset/selectors'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DataSource } from '../components/editable-table'
import { convertToDataSource, getCalculatedDataSource } from '../helpers'
import {
  calculateAcceleratedDepreciation,
  filteredDataSourceByLedgerSetting,
  setInitialDepreciationAmount,
} from '../helpers/calculate-accelerated-depreciation'

export const useCalculateAcceleratedDepreciation = (depreciationResults: any, selectedDate?: string) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([])
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)
  const assetDetail = useSelector(selectAssetItemData, isEqual)
  const accountingLedger = findAccountingLedger(ledgerSettings as LedgerSettingsItem[])
  const currentTermOfFiscalYear = currentMonthlyClosing?.to_term_month?.term
  const acquiredAt = get(assetDetail, 'acquired_at') as string
  const isFixedAssetNotBelongToCurrentFiscalYear = checkIsAfterDate(acquiredAt, currentTermOfFiscalYear?.end_date)

  const startDate = currentMonthlyClosing?.from_term_month?.start_date
  const endDate = currentMonthlyClosing?.to_term_month?.end_date
  const retirementDepreciationEndedMethod = accountingLedger?.retirement_depreciation_ended_method as number

  const isPlannedDateBelongToCurrentFiscalYear = moment(selectedDate).year() === currentTermOfFiscalYear?.year

  useEffect(() => {
    if (selectedDate && depreciationResults.data.length) {
      if (
        isFixedAssetNotBelongToCurrentFiscalYear ||
        (isPlannedDateBelongToCurrentFiscalYear &&
          retirementDepreciationEndedMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR)
      ) {
        setDataSource([])
      } else {
        const dataSource = convertToDataSource(depreciationResults.data, { startDate, endDate })
        const filteredDataSource = filteredDataSourceByLedgerSetting(
          dataSource,
          selectedDate,
          retirementDepreciationEndedMethod
        )

        const initialDataSource = setInitialDepreciationAmount(filteredDataSource)
        setDataSource(getCalculatedDataSource(initialDataSource))
      }
    }
  }, [depreciationResults, selectedDate])

  const handleInputChange = (rowIndex: number, value?: number) => {
    const newDataSource = calculateAcceleratedDepreciation(dataSource, rowIndex, value)
    setDataSource(getCalculatedDataSource(newDataSource))
  }

  return { dataSource, handleInputChange }
}
