import { Spin } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { MainTemplate } from 'components/templates'
import { find, get, isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { getUserInfoSelector, selectCurrentOffice } from 'store/session/selectors'

import { parseOfficeNumber } from './helpers'
import { UserInfo } from './styles'

const t = loadNs(['pages/settings/office'])

const { REACT_APP_NAVIS_SERVICE_URL } = process.env

const OFFICE_TENANT_TYPES = [
  {
    value: 'corporate',
    label: t('officeType.corporate'),
  },
  {
    value: 'individual',
    label: t('officeType.individual'),
  },
]

const renderOfficeType = (type: string) => {
  const target = find(OFFICE_TENANT_TYPES, item => item.value === type)
  return target ? target.label : ''
}

export const OfficeShowOldPage = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('breadcrumb_title')} />
  const currentOfficeSelector = useSelector(selectCurrentOffice, isEqual)
  const userInfo = useSelector(getUserInfoSelector, isEqual)

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: 900, marginBottom: 10 }}>
        <a
          href={`${REACT_APP_NAVIS_SERVICE_URL}/sso?tenant_uid=${userInfo?.office_id}&redirect_path=${REACT_APP_NAVIS_SERVICE_URL}/app&login_hint=${userInfo?.email}`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: 13,
            width: 152,
            background: 'linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 244) 100%)',
            border: '1px solid rgb(212, 216, 221)',
            padding: '4px 10px',
            borderRadius: 4,
          }}
        >
          {t('go_to_navis')}
        </a>
      </div>
      <UserInfo>
        <Spin loading={currentOfficeSelector.loading}>
          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_name')}</h4>
            </div>
            <div className="content">{get(currentOfficeSelector.data, 'name', '')}</div>
          </div>

          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_number')}</h4>
            </div>
            <div className="content">
              {parseOfficeNumber(get(currentOfficeSelector.data, 'identification_code', ''))}
            </div>
          </div>

          <div className="field-item">
            <div className="label">
              <h4>{t('info.office_type')}</h4>
            </div>
            <div className="content">{renderOfficeType(currentOfficeSelector.data?.tenant_type)}</div>
          </div>
        </Spin>
      </UserInfo>
    </MainTemplate>
  )
}
