/* eslint-disable react-hooks/exhaustive-deps */
import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import {
  AUTOMATIC_JOURNAL_ENTRY_ACQUISITION_TYPE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  EXCISE_TAX_METHOD,
  JOURNAL_EVENT_TYPE,
} from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { JournalData } from 'common/models'
import { NoDataImage } from 'components/atoms'
import { Pagination as PaginationTable } from 'components/molecules'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCountOfJournalEvents, fetchJournalsReport } from 'store/accounting/journal/actions'
import { selectCountOfJournalEvents } from 'store/accounting/journal/selectors'

import { genColumns } from '../JournalList/settings'
import { INITIAL_VALUES } from './consts'
import FormFilter from './Form'
import MessageNoJournals from './MessageNoJournals'
import { Table, Wrapper } from './styles'

interface Props {
  journals: JournalData[]
  totalRecords: number
  exciseSettingByDate: any
  monthlyClosingCurrent?: any
}

const t = loadNs(['components/organisms/accounting/journal-list'])
const JournalList = ({ journals, totalRecords, exciseSettingByDate, monthlyClosingCurrent }: Props) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState(INITIAL_VALUES)
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)

  const journalCount = useSelector(selectCountOfJournalEvents, isEqual)

  const onPageChanged = (page: number, pageSize = DEFAULT_PAGE_SIZE) => {
    setCurrentPage(page)
    dispatch(fetchJournalsReport(values, page, pageSize))
  }

  const onFetchJournals = (values: any) => {
    dispatch(fetchJournalsReport(values, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE))
    setValues(values)
    setCurrentPage(DEFAULT_PAGE_NUMBER)
  }

  const onFetchCountOfJournalEvents = (term_month_id: number) => {
    dispatch(fetchCountOfJournalEvents({ term_month_id }))
  }

  const isNoJournals =
    get(exciseSettingByDate, 'data.automatic_journal_entry_accquisition_type') ===
      AUTOMATIC_JOURNAL_ENTRY_ACQUISITION_TYPE.NOT_EXECUTED &&
    values.journal_event_type === JOURNAL_EVENT_TYPE.ACQUISITION
  return (
    <Wrapper>
      <FormFilter
        valuesForm={values}
        journalCount={journalCount}
        onFetchJournals={onFetchJournals}
        onFetchCountOfJournalEvents={onFetchCountOfJournalEvents}
        monthlyClosingCurrent={monthlyClosingCurrent}
      />
      {/* show messageNoJournals when automatic_journal_entry_accquisition_type and open tab 1 */}
      <If condition={isNoJournals}>
        <MessageNoJournals />
      </If>

      <If condition={!isNoJournals}>
        <If condition={journals?.length}>
          <>
            <div style={{ float: 'right', marginBottom: 15, display: 'flex' }}>
              <PaginationTable
                value={currentPage}
                name="fixed-asset"
                totalPages={
                  Math.floor(totalRecords / DEFAULT_PAGE_SIZE) + ifElse(totalRecords % DEFAULT_PAGE_SIZE > 0, 1, 0)
                }
                totalRecords={totalRecords}
                onPageChanged={onPageChanged}
              />
            </div>
            <Table
              tableLayout="fixed"
              columns={genColumns({
                isIncluded: exciseSettingByDate?.data?.tax_method === EXCISE_TAX_METHOD.INCLUDED_TAX,
                lastKey: 'links',
              })}
              dataSource={journals}
              pagination={false}
            />
          </>
        </If>
        <If condition={!journals?.length}>
          <div style={{ textAlign: 'center', marginTop: 150 }}>
            <NoDataImage text={t('not_have_journals')} imageHeight={200} />
          </div>
        </If>
      </If>
    </Wrapper>
  )
}

export default JournalList
