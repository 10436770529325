import { ifElse } from 'aa_common/front-end/helpers'
import { DATE_FORMAT_RFC } from 'common/constants'
import { get } from 'lodash'
import moment, { Moment } from 'moment/moment'
import { AREA_DETAIL_MODE, AreaDataItem, IAreaDetail } from 'store/settings/area/model'
import { DepartmentItem, IDepartmentDataItem } from 'store/settings/department/model'

import { updateObjectValues } from '../../../../common/helpers'
import { INITIAL_VALUES } from './constants'

export interface EditValueFields {
  attachments: any[]
  biid: string | null
  code: string
  id: number | null
  id_after: number | undefined
  id_before: number | undefined
  name: string
  photos: any[]
  search_key: string
  submit_destination: {
    id: number
    code: string
    name: string
    search_key: string
  }
  submit_destination_id: number | null
  urls: any[]
  valid_from: any
  valid_from_min: any
  valid_to: any
  valid_to_max: any
}

export const validateForm = (values: EditValueFields) => {
  const { name, valid_from, submit_destination_id, photos } = values
  let isPhotoUploading

  photos?.forEach(item => {
    isPhotoUploading = item?.status === 'uploading'
  })

  return !!(name.trim() && valid_from && submit_destination_id && !isPhotoUploading)
}

export const isTheFirstTable = (afterId: number | undefined) => {
  return !afterId
}

export const convertSubmitPayload = (values: any) => {
  const { submit_destination_id, ...rest } = values
  return {
    submit_destination_id:
      typeof submit_destination_id === 'object' ? submit_destination_id?.value : submit_destination_id,
    ...rest,
  }
}

export const getValidData = (
  initialValueModal: AreaDataItem | DepartmentItem,
  initialValues: AreaDataItem | DepartmentItem | null,
  formDataList: IAreaDetail[] | IDepartmentDataItem[]
) => {
  let nextValidTo: Moment | null = null
  let beforeValidFrom: Moment | null = null
  const isIdAfter = !!get(initialValueModal, 'id_after')
  const isIdBefore = !!get(initialValueModal, 'id_before')

  const id = get(initialValueModal, 'id')
  const validFromMin = moment(get(initialValueModal, 'valid_from_min')).format(DATE_FORMAT_RFC)
  const validToMax = moment(get(initialValueModal, 'valid_to_max')).format(DATE_FORMAT_RFC)
  const isValidToMax = !!get(initialValues, 'valid_to_max')
  const validToMaxValue = ifElse(isValidToMax, validToMax, null)
  const isValidToMaxNotCopy = !!get(initialValueModal, 'valid_to_max')
  const validToMaxNotCopy = ifElse(isValidToMaxNotCopy, validToMax, null)

  formDataList.forEach((item: IAreaDetail | IDepartmentDataItem) => {
    if (initialValueModal.id && initialValueModal.id_before === item?.id) {
      nextValidTo = moment(item?.data?.valid_to).subtract(1, 'day')
    } else if (initialValueModal.id && initialValueModal.id_after === item?.id) {
      beforeValidFrom = moment(item?.data?.valid_from).add(1, 'day')
    }
  })

  return {
    nextValidTo,
    beforeValidFrom,
    isIdAfter,
    isIdBefore,
    id,
    validFromMin,
    isValidToMax,
    validToMaxValue,
    validToMaxNotCopy,
  }
}

export const getFormData = (beforeValue: any, afterValue: any, value: any, currentTime: any, activeAreaId: any) => {
  const objectValues = updateObjectValues(INITIAL_VALUES, value)
  const isActiveItem = value.id === activeAreaId
  return {
    formData: {
      id: value.id,
      key: currentTime + value.id,
      status: isActiveItem ? AREA_DETAIL_MODE.EDIT : AREA_DETAIL_MODE.VIEW,
      data: {
        ...objectValues,
        submit_destination_id: value.submit_destination?.id,
        submit_destination: value.submit_destination,
        valid_from_min: beforeValue ? moment(beforeValue.valid_from) : null,
        valid_to_max: afterValue ? moment(afterValue.valid_to) : null,
        id_before: get(afterValue, 'id'),
        id_after: get(beforeValue, 'id'),
      },
    } as IAreaDetail,
    isActiveItem,
  }
}
